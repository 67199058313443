import React from 'react';
import { FieldScript, NamedPlainSqlQuery, NamedQueryObjectProto, PlainSqlQueries, QueryObjects } from '../../types/QueryObjectProto.types';
import { iconSize } from '../../utils/Utils';
import { ReactComponent as ThirdParty } from '../../assets/Applications.svg';
import { ReactComponent as Assets } from '../../assets/Assets.svg';
import { ReactComponent as Data } from '../../assets/Data.svg';
import { ReactComponent as Network } from '../../assets/Network.svg';
import { ReactComponent as NotPrivate } from '../../assets/NotPrivate.svg';
import { ReactComponent as Applications } from '../../assets/risk/Applications.svg';
import { ReactComponent as Sorting } from '../../assets/Sorting.svg';
import { ReactComponent as User } from '../../assets/user.svg';

export const ExecutionRuleTypes = {
  RISK_FACTOR: 'RISK_FACTOR',
};

export type BaseExecutionRule = {
  id?: string;
  executables: ExecutableUnit[];
  preChecks: ExecutableUnit[];
  type: (typeof ExecutionRuleTypes)[keyof typeof ExecutionRuleTypes];
  active: boolean;
  updatedAt?: string;
  metadata: FactorLogicExecutionRuleMetadata;
  createdByUserId?: string;
  updatedByUserId?: string;
};

export type ExecutableQueryObjects = {
  queryObjects: QueryObjects;
};

export type ExecutablePlainSqlQueries = {
  sqlQueries: PlainSqlQueries;
};

export type FactorLogicExecutionRule = BaseExecutionRule;

export type BuiltInFactorLogicExecutionRule = BaseExecutionRule;

export type FactorRule = FactorLogicExecutionRule | BuiltInFactorLogicExecutionRule;

export type ExecutableUnit = {
  expression: FieldScript;
} & (ExecutableQueryObjects | ExecutablePlainSqlQueries);

export type ExecutableUnitQueryObjects = {
  expression: FieldScript;
} & ExecutableQueryObjects;

export type ExecutableUnitPlainSqlQueries = {
  expression: FieldScript;
} & ExecutablePlainSqlQueries;

export type FactorLogicExecutionRuleMetadata = {
  externalId?: string;
  name?: string;
  description?: string;
  categoryId?: FactorCategoryId;
  categoryName?: FactorCategoryNameType;
  groupId?: string;
  groupName?: string;
  builtIn?: boolean;
  recommendation?: string;
  note?: string;
  includeInEffectiveScore?: boolean;
  weight?: number;
  rawScore?: number;
  normalizedScore?: number;
  effectiveNormalizedScore?: number;
  relatedInsightsIds?: number[];
  prodSkuIds?: string[];
  executionRuleId?: string;
  researchStatus?: ResearchStatus;
  tags?: string[];
  isGroup?: boolean;
  type: FactorType;
  insightId?: number;
};

export enum FactorType {
  GROUP_FACTOR = 'GROUP_FACTOR',
  SUB_FACTOR = 'SUB_FACTOR',
  STANDALONE_FACTOR = 'STANDALONE_FACTOR',
}

export const FactorTypeNames = {
  [FactorType.GROUP_FACTOR]: 'Group Factor',
  [FactorType.SUB_FACTOR]: 'Sub Factor',
  [FactorType.STANDALONE_FACTOR]: 'Standalone',
};

export enum FactorRelatedEntity {
  '3RD' = '3rd Parties',
  APPLICATIONS = 'Applications',
  ASSETS = 'Assets',
  WORKFORCE = 'Workforce',
}

export const factorRelatedEntitiesIcon = (size = 16) => ({
  [FactorRelatedEntity['3RD']]: <ThirdParty style={iconSize(size)} />,
  [FactorRelatedEntity.APPLICATIONS]: <Applications style={iconSize(size)} />,
  [FactorRelatedEntity.ASSETS]: <Assets style={iconSize(size)} />,
  [FactorRelatedEntity.WORKFORCE]: <User style={iconSize(size)} />,
});

export enum FactorCategoryId {
  EXTERNAL_ATTACK_SURFACE = '1',
  COMPORMISE = '2',
  LATERAL_PROPAGATION = '3',
  DATA_LOSS = '4',
}
export const FactorCategory = {
  [FactorCategoryId.COMPORMISE]: 'Compromise',
  [FactorCategoryId.DATA_LOSS]: 'Data Loss',
  [FactorCategoryId.EXTERNAL_ATTACK_SURFACE]: 'External Attack Surface',
  [FactorCategoryId.LATERAL_PROPAGATION]: 'Lateral Propagation',
};

export type FactorCategoryNameType = (typeof FactorCategory)[keyof typeof FactorCategory];

export const factorCategoryIcon = (size = 16) => ({
  [FactorCategoryId.COMPORMISE]: <NotPrivate style={iconSize(size)} />,
  [FactorCategoryId.DATA_LOSS]: <Data style={iconSize(size)} />,
  [FactorCategoryId.EXTERNAL_ATTACK_SURFACE]: <Network style={iconSize(size)} />,
  [FactorCategoryId.LATERAL_PROPAGATION]: <Sorting style={iconSize(size)} />,
});

export enum ResearchStatus {
  ENABLED = 'ENABLED',
  MODELING = 'MODELING',
  DISABLED = 'DISABLED',
}

export const ResearchStatusName = {
  [ResearchStatus.ENABLED]: 'Enabled',
  [ResearchStatus.MODELING]: 'Modeling',
  [ResearchStatus.DISABLED]: 'Disabled',
};

export const prodSkuIds = [
  'PROD-ZIA',
  'PROD-ZIA-ATP',
  'PROD-ZIA-CLD-APP-CTRL',
  'PROD-ZS-PSTR-CTRL',
  'PROD-ZPA',
  'PROD-DLP-ESS',
  'PROD-DLP-ZIA-CASB-1-APP',
  'PROD-ZS-DP-PVT-APPS',
  'PROD-ZIA-CASB-ALL-APPS',
  'PROD-ZIA-CASB-APP-APPS',
  'PROD-ZIA-SANDBOX',
  'PROD-ZIA-ISO-STD',
  'PROD-ZIA-ISO5',
  'PROD-ZIA-FIREWALL',
  'PROD-ZIA-SSL',
  'PROD-ZDX-UCAAS',
  'PROD-ZIA-IPS-CTRL',
  'PROD-ZPA-PVT-SVC-EDGE',
  'PROD-ZPA-APP-PROT',
  'PROD-ZS-DECEPTION-STD',
  'PROD-ZIA-DLP-EDM',
  'PROD-ZS-DP-INLINE-WEB',
  'PROD-ZPA-ISO-STD',
  'PROD-ZIA-SERVER-GB',
  'ZS-DP-SAAS-3PA-PRE',
  'PROD-ZS-DP-APP-TOTAL',
  'PROD-ZS-DP-SAAS-API',
  'PROD-DLP-ESS',
];

export const defaultCelPerType = {
  [FactorType.GROUP_FACTOR]: `io.avalor.protocol.entities.v2.Factor {
    name: metadata.name,
    description: metadata.description,
    execution_rule_id: metadata.executionRuleId,
    built_in: metadata.builtIn,
    include_in_effective_score: metadata.includeInEffectiveScore,
    category_id: metadata.categoryId,
    category_name: metadata.categoryName,
    group_id: metadata.groupId,
    recommendation: metadata.recommendation,
    note: metadata.note,
    type: metadata.type,
    tags: metadata.tags,
    insight_id: metadata.insightId, // remove if not relevant
    external_id: '',
    prod_sku_ids: metadata.prodSkuIds,
    related_insights_ids: metadata.relatedInsightsIds,
    is_licensed: true,
    research_status: metadata.researchStatus,
}`,
  [FactorType.SUB_FACTOR]: `io.avalor.protocol.entities.v2.Factor {
    name: metadata.name,
    description: metadata.description,
    execution_rule_id: metadata.executionRuleId,
    built_in: metadata.builtIn,
    include_in_effective_score: metadata.includeInEffectiveScore,
    category_id: metadata.categoryId,
    category_name: metadata.categoryName,
    group_id: metadata.groupId,
    recommendation: metadata.recommendation,
    tags: metadata.tags,
    note: metadata.note,
    weight: metadata.weight,
    type: metadata.type,
    insight_id: metadata.insightId, // remove if not relevant
    external_id: '',
    prod_sku_ids: metadata.prodSkuIds,
    related_insights_ids: metadata.relatedInsightsIds,
    is_licensed: true,
    research_status: metadata.researchStatus,
    raw_score: 0.0
}`,
  [FactorType.STANDALONE_FACTOR]: `io.avalor.protocol.entities.v2.Factor {
    name: metadata.name,
    description: metadata.description,
    execution_rule_id: metadata.executionRuleId,
    built_in: metadata.builtIn,
    include_in_effective_score: metadata.includeInEffectiveScore,
    category_id: metadata.categoryId,
    category_name: metadata.categoryName,
    recommendation: metadata.recommendation,
    tags: metadata.tags,
    note: metadata.note,
    weight: metadata.weight,
    type: metadata.type,
    insight_id: metadata.insightId, // remove if not relevant
    external_id: '',
    prod_sku_ids: metadata.prodSkuIds,
    related_insights_ids: metadata.relatedInsightsIds,
    is_licensed: true,
    research_status: metadata.researchStatus,
    raw_score: 0.0
}`,
};

export type AnyQuery = NamedQueryObjectProto | NamedPlainSqlQuery;

export enum FactorKind {
  ACCOUNT = 'executionRuleDto',
  BUILT_IN = 'builtInExecutionRuleDto',
}
