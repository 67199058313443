import React, { CSSProperties, ReactNode } from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import NoDataFoundSVG from '../assets/NoDataFound.webp';

interface NoDataFoundProps {
  img?: any;
  imgStyle?: CSSProperties;
  headline?: string;
  callToAction?: ReactNode;
  size?: 'large' | 'medium' | 'small' | 'responsive';
}

const NoDataFound: React.FC<NoDataFoundProps> = ({
  img = NoDataFoundSVG,
  imgStyle,
  headline = 'No Data Found',
  size = 'responsive',
  callToAction,
}) => (
  <Box sx={emptyStateStyle(size)}>
    <img style={imgStyle} src={img} alt="No data found" />
    <Typography variant={size === 'small' ? 'h5' : 'h3'}>{headline}</Typography>
    {callToAction && <Typography variant="h6">{callToAction}</Typography>}
  </Box>
);

export default NoDataFound;

const emptyStateStyle = size => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  gap: '8px',
  flexGrow: 1,
  height: '100%',
  img: {
    marginBottom: size === 'large' ? '48px' : '40px',
    height: size === 'responsive' ? '50%' : 'unset',
    aspect: size === 'responsive' ? 1 / 1 : 'unset',
  },
  h3: {
    color: theme => theme.palette.colors.neutrals[600],
    ...((size === 'medium' || size === 'responsive') && {
      fontSize: '18px',
      marginBottom: 1,
    }),
  },
  h5: {
    color: theme => theme.palette.colors.neutrals[600],
  },
  h6: {
    display: 'flex',
    whiteSpace: 'pre',
    color: theme => theme.palette.colors.neutrals[500],
    '.MuiButton-root': {
      color: theme => theme.palette.primary.main,
    },
  },
});
