import { GroupHeaderRowType } from '../components/Table/types';
import { getGroupComparator } from '../components/Table/Utils';
import { SearchContextKeys } from '../context/SearchContext.types';
import { APP_PATHS, PAGE_PATHS, Permission, PermissionEntitiesNames } from '../types';
import { ScreenType } from '../types/savedViews.types';
import { FactorType } from '../views/Tickets/Factors/utils';
import { getHeadCellWithExpended } from '../views/Tickets/Utils';
import { defaultAlertSortBy, defaultSortBy, FilterIncidentEntityTypesLabelV2, FilterTypesLabelV2, TicketType } from './mapping';

export const ACTIONS_MAP = {
  UPDATE: 'update',
  MERGE: 'merge',
  COMMENT: 'comment',
  OUTEGRATION_CREATE: 'outegration_create',
  ALERT_TO_INCIDENT: 'alert_to_incident',
  ATTACHMENTS: 'attachments',
  OUTEGRATION_ACT: 'outegration_act',
};
export const ACTIONS_PERMISSIONS_MAP = {
  [ACTIONS_MAP.UPDATE]: { resource: PermissionEntitiesNames.OPT, permission: Permission.UPDATE },
  [ACTIONS_MAP.MERGE]: { resource: PermissionEntitiesNames.OPT, permission: Permission.CREATE },
  [ACTIONS_MAP.COMMENT]: { resource: PermissionEntitiesNames.OPT, permission: Permission.UPDATE },
  [ACTIONS_MAP.OUTEGRATION_CREATE]: { resource: PermissionEntitiesNames.INT, permission: Permission.CREATE },
  [ACTIONS_MAP.ALERT_TO_INCIDENT]: { resource: PermissionEntitiesNames.OPT, permission: Permission.UPDATE },
  [ACTIONS_MAP.ATTACHMENTS]: { resource: PermissionEntitiesNames.OPT, permission: Permission.UPDATE },
  [ACTIONS_MAP.OUTEGRATION_ACT]: { resource: PermissionEntitiesNames.INT, permission: Permission.UPDATE },
};
// eslint-disable-next-line import/prefer-default-export
export const entityViewConfig = {
  Ticket: {
    path: PAGE_PATHS.TICKETS,
    entityTypeId: 'Ticket',
    projectionName: 'tickets',
    pageTitle: 'Remediation Hub',
    appPath: APP_PATHS.VULNERABILITIES,
    app: 'Vulnerabilities',
    viewScreenType: ScreenType.Tickets,
    labelsV2: FilterTypesLabelV2,
    showVisualEntityExplorer: true,
    searchFields: ['ticket.title'],
    defaultSavedView: 'Active',
    searchContextKey: SearchContextKeys.Tickets,
    vulnsField: 'ticket.finding_count',
    extraFilters: ["ticket.state != 'INACTIVE'"],
    groupingKeyField: 'finding.grouping_key',
    titleFieldName: 'title',
    extraRelationGroup: 'Assignee',
    isTicketFilters: true,
    defaultSortBy,
    actions: {
      grid: {
        [ACTIONS_MAP.UPDATE]: true,
        [ACTIONS_MAP.MERGE]: true,
        [ACTIONS_MAP.COMMENT]: true,
        [ACTIONS_MAP.OUTEGRATION_CREATE]: true,
        [ACTIONS_MAP.OUTEGRATION_ACT]: true,
      },
      panel: {
        [ACTIONS_MAP.ATTACHMENTS]: true,
      },
    },
    styles: {
      isTransparentPageBackground: true,
    },
  },
  Asset: {
    path: PAGE_PATHS.ASSETS,
    entityTypeId: 'Asset',
    projectionName: 'uber_assets',
    pageTitle: 'Assets',
    appPath: APP_PATHS.VULNERABILITIES,
    app: 'Vulnerabilities',
    viewScreenType: ScreenType.AssetView,
    showVisualEntityExplorer: true,
    labelsV2: FilterTypesLabelV2,
    defaultFilters: {
      'asset.source_names': [],
      'asset.type': [],
      'asset.site': [],
      'asset.os': [],
    },
    searchFields: ['asset.name'],
    defaultSavedView: 'Active',
    searchContextKey: SearchContextKeys.Assets,
    vulnsField: '',
    extraFilters: [],
    hideCommentsTab: true,
    hideActivityTab: true,
    extraRelationGroup: 'Owner',
    titleFieldName: 'name',
    defaultSortBy,
    actions: {
      grid: {
        [ACTIONS_MAP.UPDATE]: true,
        // Added to support Act button [AVA-9992] fixed in [AVA-10084]
        [ACTIONS_MAP.OUTEGRATION_ACT]: true,
      },
      panel: { [ACTIONS_MAP.ATTACHMENTS]: false },
    },
    styles: {
      isTransparentPageBackground: true,
    },
  },
  Incident: {
    path: PAGE_PATHS.INCIDENTS,
    entityTypeId: 'Incident',
    projectionName: 'incidents',
    pageTitle: 'Incidents',
    app: 'Incidents',
    appPath: APP_PATHS.DETECTIONS,
    viewScreenType: ScreenType.Incidents,
    labelsV2: FilterIncidentEntityTypesLabelV2,
    showVisualEntityExplorer: true,
    searchFields: ['incident.title'],
    defaultSavedView: 'Open Tickets',
    searchContextKey: SearchContextKeys.Incidents,
    groupingKeyField: 'alert.grouping_key',
    titleFieldName: 'title',
    vulnsField: 'incident.alert_count',
    extraFilters: ["incident.state NOT IN ('INACTIVE', 'ARCHIVED')"],
    isTicketFilters: true,
    typesForUIConfig: [
      TicketType.CrowdstrikeDetection,
      TicketType.FileRisk,
      TicketType.ManualIncident,
      TicketType.DefenderIncident,
      TicketType.AlertToIncident,
    ],
    defaultSortBy,
    actions: {
      grid: {
        [ACTIONS_MAP.UPDATE]: true,
        [ACTIONS_MAP.MERGE]: true,
        [ACTIONS_MAP.COMMENT]: true,
        [ACTIONS_MAP.OUTEGRATION_CREATE]: true,
        [ACTIONS_MAP.OUTEGRATION_ACT]: true,
      },
      panel: { [ACTIONS_MAP.ATTACHMENTS]: true },
    },
    styles: {
      isTransparentPageBackground: true,
    },
  },
  Alert: {
    path: PAGE_PATHS.ALERTS,
    entityTypeId: 'Alert',
    projectionName: 'uber_alerts',
    pageTitle: 'Alerts',
    app: 'Incidents',
    appPath: APP_PATHS.DETECTIONS,
    viewScreenType: ScreenType.Alerts,
    showVisualEntityExplorer: true,
    labelsV2: FilterIncidentEntityTypesLabelV2,
    searchFields: ['alert.title'],
    defaultSavedView: 'Active',
    searchContextKey: SearchContextKeys.Alerts,
    titleFieldName: 'rule_name',
    defaultFilters: {
      'alert.severity': ['CRITICAL', 'HIGH', 'MEDIUM', 'LOW'],
      'alert.state': [],
      'alert.created': [],
      'alert.tactic_name': [],
      'alert.technique_name': [],
    },
    hideCommentsTab: true,
    hideActivityTab: true,
    vulnsField: '',
    defaultSortBy: defaultAlertSortBy,
    actions: {
      grid: {
        [ACTIONS_MAP.UPDATE]: true,
        [ACTIONS_MAP.ALERT_TO_INCIDENT]: true,
      },
      panel: {
        [ACTIONS_MAP.ATTACHMENTS]: false,
      },
    },
    styles: {
      isTransparentPageBackground: false,
      tableSize: 'medium',
    },
  },
  Finding: {
    path: PAGE_PATHS.FINDINGS,
    entityTypeId: 'Finding',
    projectionName: 'uber_findings',
    pageTitle: 'Findings',
    appPath: APP_PATHS.VULNERABILITIES,
    app: 'Vulnerabilities',
    viewScreenType: ScreenType.FindingsView,
    showVisualEntityExplorer: true,
    labelsV2: FilterTypesLabelV2,
    searchFields: ['finding.title', 'finding.cve_id', 'component.name', 'component._key', 'asset.os'],
    defaultSavedView: 'Active Critical and High Findings',
    defaultFilters: {
      'finding.source_names': [],
      'finding.type': [],
      'finding.severity': [],
    },
    searchContextKey: SearchContextKeys.Findings,
    extraFilters: ["finding.state != 'ARCHIVED'"],
    extraRelationGroup: '*',
    titleFieldName: 'title',
    defaultSortBy,
    hideCommentsTab: true,
    hideActivityTab: true,
    actions: {
      grid: { [ACTIONS_MAP.UPDATE]: true, [ACTIONS_MAP.MERGE]: true },
      panel: { [ACTIONS_MAP.ATTACHMENTS]: false },
    },
    styles: {
      isTransparentPageBackground: false,
      tableSize: 'medium',
    },
  },
  Factor: {
    path: PAGE_PATHS.FACTOR,
    entityTypeId: 'Factor',
    projectionName: 'uber_factors',
    pageTitle: 'Factors',
    isMultiSelect: false,
    appPath: APP_PATHS.RISK360,
    app: 'Risk360',
    labelsV2: FilterTypesLabelV2, //--------
    searchFields: ['factor.name', 'factor.category_name'],
    defaultFilters: {
      'factor.name': [],
      'factor.category_name': [],
      'factor.enabled': [],
    },
    searchContextKey: SearchContextKeys.Factor,
    disablePagination: true,
    extraFilters: [],
    rowGroupByField: row => row?.groupId,
    getHeadCellFunc: hc => getHeadCellWithExpended({ headCells: hc }),
    getData: queryData =>
      queryData
        .map(row => ({
          ...row,
          // last30Days: factorLineChartData,
          disableStickyRow: true,
          groupHeaderType:
            row.type === FactorType.GROUP_FACTOR
              ? GroupHeaderRowType.GROUP
              : row.type === FactorType.STANDALONE_FACTOR
                ? GroupHeaderRowType.STANDALONE
                : GroupHeaderRowType.SUB_GROUP,
        }))
        .toSorted(getGroupComparator(row => row?.groupId, 'asc')),
    extraRelationGroup: '*',
    titleFieldName: 'name',
    defaultSortBy,
    hideCommentsTab: true,
    hideActivityTab: true,
    hideGroupBy: true,
    actions: {
      grid: { [ACTIONS_MAP.UPDATE]: false, [ACTIONS_MAP.MERGE]: false },
      panel: { [ACTIONS_MAP.ATTACHMENTS]: false },
    },
    styles: {
      isTransparentPageBackground: true,
    },
  },
  PolicyViolation: {
    path: PAGE_PATHS.POLICY_VIOLATION,
    entityTypeId: 'PolicyViolation',
    projectionName: 'uber_policy_violations',
    pageTitle: 'Policy Violations',
    isMultiSelect: true,
    appPath: APP_PATHS.ASSETS,
    app: 'Asset',
    defaultSavedView: 'Active',
    viewScreenType: ScreenType.PolicyViolations,
    labelsV2: FilterTypesLabelV2,
    searchFields: ['policy_violation.title'],
    defaultFilters: {
      'policy_violation.state': [],
      'policy_violation.severity': [],
      'policy_violation.policy_name': [],
    },
    searchContextKey: SearchContextKeys.PolicyViolation,
    disablePagination: false,
    extraFilters: [],
    extraRelationGroup: '*',
    titleFieldName: 'title',
    defaultSortBy,
    hideCommentsTab: true,
    hideActivityTab: true,
    hideGroupBy: false,
    actions: {
      grid: { [ACTIONS_MAP.UPDATE]: true, [ACTIONS_MAP.MERGE]: false },
      panel: { [ACTIONS_MAP.ATTACHMENTS]: false },
    },
    styles: {
      isTransparentPageBackground: false,
      tableSize: 'medium',
    },
  },
};
