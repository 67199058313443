import React, { useEffect } from 'react';
import { useTheme } from '@mui/material';

export const ZendeskAPI = (...args) => {
  if ((window as any).zE) {
    (window as any).zE.apply(null, args);
  } else {
    console.warn('Zendesk is not initialized yet');
  }
};

export const ZendeskUser = user => {
  if ((window as any).zE && (window as any).zE.identify) {
    (window as any).zE.identify(user);
  } else {
    console.warn('Zendesk is not initialized yet');
  }
};

interface ZendeskProps {
  offset: Record<string, any>;
  onLoaded: () => void;
  position?: Record<string, any>;
}

const Zendesk: React.FC<ZendeskProps> = ({ offset, onLoaded, position }) => {
  const theme = useTheme();
  const insertScript = () => {
    const script = document.createElement('script');
    script.defer = true;
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=40ba830b-8a74-46c5-a05a-a9c447a708da';
    script.addEventListener('load', onLoaded);
    document.body.appendChild(script);
  };

  useEffect(() => {
    if (!(window as any).zE) {
      insertScript();
      (window as any).zESettings = {
        onLoaded,
        color: {
          theme: theme.palette.colors.neutrals[100],
          button: theme.palette.colors.primary[500],
          text: theme.palette.colors.neutrals[800],
        },
        offset,
        position,
      };
    }

    return () => {
      if ((window as any).zE) {
        delete (window as any).zE;
        delete (window as any).zESettings;
      }
    };
  }, []);

  return null;
};

export default Zendesk;
