import { useEffect, useRef } from 'react';
import { gql } from '@apollo/client';
import { useQuery } from '@tanstack/react-query';
import { IDisposable } from 'monaco-editor';
import { getEmptyFilterExpression } from '../../../components/filters/Utils';
import { useAvContext } from '../../../context/AvContextProvider';
import { FeatureFlags, Permission } from '../../../types';
import { orderByKey } from '../../../utils/Utils';
import { GroupingRuleSetTypes, SubAutoComplete } from './types';

// eslint-disable-next-line import/prefer-default-export
export const useGroupingRulesSet = projectionName => {
  const { api, featureFlags } = useAvContext();
  return useQuery({
    queryKey: ['groupingRules', projectionName],
    queryFn: () =>
      api(featureFlags[FeatureFlags.GroupingRulesV2] ? GET_LIST_V2 : GET_LIST, { options: { projectionName } }).then(data =>
        orderByKey(data?.data?.getRuleSets || data?.data?.getRuleSetsV2, 'name')
      ),
    gcTime: 0,
  });
};

const GET_LIST = gql`
  query ($projectionName: String!) {
    getRuleSets(projectionName: $projectionName)
  }
`;

const GET_LIST_V2 = gql`
  query ($projectionName: String!) {
    getRuleSetsV2(projectionName: $projectionName)
  }
`;

export const useGetProjectionFieldCompletions: ({ projectionName }: { projectionName: any }) => (editor, monaco) => void = ({
  projectionName,
}) => {
  const { featureFlags } = useAvContext();
  const disposeOptionsRef = useRef<IDisposable>();
  const { aggProjs } = useAvContext().accountEntities;
  const subProj = SubAutoComplete[projectionName];
  const subTitleFilterOptions = (subProj && aggProjs[subProj].fieldList.SPF.filter(f => !f.visibilityConfig.config.hidden)) || [];
  const titleFilterOptions =
    aggProjs[projectionName].fieldList[
      featureFlags[FeatureFlags.GroupingRulesV2] || !featureFlags[FeatureFlags.forcePushdownUnification] ? 'EVAL' : 'SPF'
    ];

  useEffect(() => () => disposeOptionsRef.current?.dispose(), []);
  return (editor, monaco) => {
    const completions = subProj ? [...titleFilterOptions, ...subTitleFilterOptions] : titleFilterOptions;

    function createCompletionItems() {
      const completionItems = {};
      completions.forEach(item => {
        const { group } = item;
        if (!completionItems[group]) {
          completionItems[group] = [];
        }

        const completionItem = {
          label: item.title,
          kind: monaco.languages.CompletionItemKind.Field,
          insertText: item.value, // Use the 'value' property as the inserted value
          range: null, // Set the range if needed, else leave as null
        };
        completionItems[group].push(completionItem);
      });

      return (Object.keys(completionItems) || []).reduce((acc: any[], group) => [...acc, ...completionItems[group]], []);
    }

    // Register the completion provider with Monaco Editor
    const registeredOptions = monaco.languages.registerCompletionItemProvider('python', {
      provideCompletionItems: (model, position) => {
        const lineContent = model.getLineContent(position.lineNumber);
        const prefix = lineContent.substring(0, position.column - 2);
        const suffix = lineContent.substring(position.column);

        // Check if there is '{{' before the current position and '}}' after the current position
        if (!prefix.endsWith('{{') || !suffix.startsWith('}')) {
          return {
            suggestions: [],
          };
        }
        // Call the function to create completions from the array
        const completions1 = createCompletionItems();
        return {
          suggestions: completions1,
        };
      },
    });

    disposeOptionsRef.current = registeredOptions;
  };
};

export const emptyRule = () => ({
  name: '',
  condition: getEmptyFilterExpression(),
  entityTitleFormat: '',
  grouping: { fieldGrouping: { fields: [] } },
});

export function getProjectionToTriggered(projectionName: string) {
  switch (projectionName) {
    case GroupingRuleSetTypes.Ticket:
      return GroupingRuleSetTypes.Finding;
    case GroupingRuleSetTypes.Incident:
      return GroupingRuleSetTypes.Alert;
    default:
      throw new Error(`Invalid projection name: ${projectionName}`);
  }
}

export const isOnReadMode = (hasPermissionsMap: Partial<Record<Permission, boolean>>) =>
  hasPermissionsMap.READ && !hasPermissionsMap.CREATE && !hasPermissionsMap.UPDATE && !hasPermissionsMap.DELETE;
