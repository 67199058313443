import { useContext } from 'react';
import { gql } from '@apollo/client';
import { useMutation } from '@tanstack/react-query';
import { useAvContext } from '../context/AvContextProvider';
import { NotificationContext, WorkflowStatus } from '../context/AvSnackBarProvider';
import { EntityTypeID } from '../context/context.type';
import { WorkflowType } from '../context/SnackBar.types';
import { Filter } from '../types/filter.types';
import { DwQueryRequest } from '../views/CustomDashboards/types';
import { FileFormat } from '../views/Reports/types';
import { PresignedUrlType, usePresignedUrl } from './usePresignedURL';

export const EXPORT_QUERY_OBJECT = gql`
  query ($exportFromQueryDto: ExportFromQueryObjectInput!) {
    exportFromQueryObject(exportFromQueryObjectDto: $exportFromQueryDto) {
      runId
      wfId
    }
  }
`;

export const EXPORT_QUERY_SQL = gql`
  query ($exportFromQueryDto: ExportFromQuerySqlInput!) {
    exportFromQuerySql(exportFromQuerySqlDto: $exportFromQueryDto) {
      runId
      wfId
    }
  }
`;

export const useExportQueryObjectWorkflow = () => useExportQuery(EXPORT_QUERY_OBJECT);
export const useExportQuerySqlWorkflow = () => useExportQuery(EXPORT_QUERY_SQL);

export const useExportQuery = query => {
  const { api } = useAvContext();
  const { setRunInfo } = useContext(NotificationContext);
  const { mutate: getReportUrl } = usePresignedUrl(PresignedUrlType.QUERY);

  type QueryParameters = { querySql: string } | { queryObject: DwQueryRequest };

  return useMutation({
    mutationFn: ({
      fileFormat = FileFormat.CSV_FORMAT,
      ...exportFromQueryDto
    }: { fileFormat?: string; projectionID?: EntityTypeID; contextFilter?: Filter } & QueryParameters) =>
      api(query, {
        options: { exportFromQueryDto: { fileFormat, ...exportFromQueryDto } },
        onSuccess: ({ data }: { data: Record<string, any> }) => {
          setRunInfo({
            ...(data.exportFromQuerySql || data.exportFromQueryObject),
            workflowType: WorkflowType.ExportQuery,
            onSuccess: ({ status }) => {
              if (status === WorkflowStatus.COMPLETED) {
                getReportUrl({ wfId: Object.values(data)[0].wfId, runId: Object.values(data)[0].runId });
              }
            },
          });
        },
      }),
  });
};
