import React, { ReactElement, useMemo, useRef } from 'react';
import { Box, Button, Divider, IconButton, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useAvContext } from '../context/AvContextProvider';
import { FieldListObject } from '../context/context.type';
import { FeatureFlags } from '../types';
import { FilterTypes } from '../utils/mapping';
import { generateOption, iconSize, prettyFieldName, rearrange, rearrangeObject } from '../utils/Utils';
import { clickthroughPrefix } from '../views/CustomDashboards/components/Clickthrough/consts';
import { FieldTypes } from '../views/Reports/types';
import { typeLabelMap } from '../views/Sources/Mapping/mapping.types';
import { filtersFieldToIgnore, getFieldLabel } from '../views/Tickets/ticket.types';
import { flex } from './AvThemeProvider';
import { FieldFilter } from './FieldFilter';
import Select from './Select';
import { ReactComponent as FilterActive } from '../assets/colorful/FilterActive.svg';
import { ReactComponent as DragSmall } from '../assets/DragSmall.svg';
import { ReactComponent as Filter } from '../assets/Filter.svg';
import { ReactComponent as Plus } from '../assets/Plus.svg';
import { ReactComponent as X } from '../assets/X.svg';

const dragIcon = <DragSmall />;
const filterIcon = <Filter style={{ width: 20, height: 20 }} />;
const filterActiveIcon = <FilterActive style={{ width: 20, height: 20 }} />;

const getSelectedFiltersMap = (selectedFilters: string[]) => selectedFilters.reduce((acc, filter) => ({ ...acc, [filter]: true }), {});
const flatArrayOfOptions = data => [...new Set(data?.map(({ value }) => value).flat(1))].map(generateOption);

const flatArrayOfOptionsWithCustomTitle = (data, label, titleGenerator = d => d) =>
  [...new Set(data?.map(({ value }) => value).flat(1))].map(value => ({ title: titleGenerator?.(value) || value, value }));
interface AvFiltersProps {
  currentTypeLabels?: { [key: string]: string };
  filters: { headCells?: string[]; [key: string]: any };
  staticFilters?: string[];
  updateFilters: (field: string, val: any) => void;
  setFilters: React.Dispatch<React.SetStateAction<any>>;
  onApplyFilter?: () => void;
  size?: 'xSmall' | 'small' | 'medium' | 'large';
  disabled?: boolean;
  isActiveFilter?: boolean;
  onClearFilters?: () => void;
  fields?: any[];
  usage?: 'SPF' | undefined;
  overrideExtra?: string[];
  activeProjName?: string;
  moreFiltersLabel?: React.ReactNode;
  displayNameMapFormatter?: { (key: string): (val: any) => string };
  children?: ReactElement<any, any>;
  queryKey?: string;
  isFilterExpression?: boolean;
  isGlobalFilter?: boolean;
  showIcon?: boolean;
  isDraggable?: boolean;
  removeFieldButton?: boolean;
}

const AvFilters: React.FC<AvFiltersProps> = ({
  currentTypeLabels = {},
  filters,
  staticFilters = [],
  updateFilters,
  setFilters,
  onApplyFilter,
  disabled,
  activeProjName,
  isActiveFilter,
  onClearFilters,
  fields: f = [],
  usage,
  overrideExtra,
  displayNameMapFormatter = {},
  size = 'xSmall',
  isFilterExpression = false,
  moreFiltersLabel = (
    <>
      <Plus /> More
    </>
  ),
  isDraggable,
  removeFieldButton,
  children,
  queryKey,
  isGlobalFilter = false,
  showIcon = false,
}) => {
  const theme = useTheme();
  const {
    featureFlags,
    measurements = { visible: [] },
    accountEntities: { fieldMap },
  } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();
  const filtersRefsMap = useRef({});
  const fields = useMemo(
    () => f.filter(field => ![...staticFilters].includes(field.value) && !field.visibilityConfig?.config?.hidden),
    [f]
  );

  const fieldMeasurementsMap = useMemo(
    () => ({
      ...fieldMap,
      ...measurements.visible.reduce(
        (acc, { systemName, displayName, type }) => ({
          ...acc,
          [systemName]: {
            value: systemName,
            pathAlias: systemName,
            title: displayName,
            group: FieldTypes.Measurement,
            entityTypeId: { builtin: true },
            type,
          },
        }),
        []
      ),
    }),
    [measurements, fieldMap]
  );

  const selectedFilters = useMemo(
    () => [
      ...new Set(
        Object.keys(filters).filter(field => {
          const isIncludedInFieldsList = !isGlobalFilter || fields.some(f => f.value === field);
          const canIgnoreFilter = filtersFieldToIgnore.includes(field) || field.includes(clickthroughPrefix);
          const isStaticFilter = staticFilters.includes(field);

          if (!isIncludedInFieldsList && !canIgnoreFilter && !isStaticFilter) {
            const error = `[Setup Error]: unknown column ${field}`;
            console.error(error);
            if (featureFlags[FeatureFlags.ShowServerErrorInToast]) {
              enqueueSnackbar(error, { variant: 'error' });
            }
          }

          return !isStaticFilter && !canIgnoreFilter && !!fieldMeasurementsMap[field] && isIncludedInFieldsList;
        })
      ),
    ],
    [filters]
  );
  const selectedFiltersMap = getSelectedFiltersMap(selectedFilters);
  const onSelectedFiltersSelect = val => {
    const newFilter = val.find(f => !selectedFilters.includes(f));
    const deletedFilter = selectedFilters.find(f => !val.includes(f));
    if (newFilter) {
      updateFilters(newFilter, []);
      setTimeout(() => filtersRefsMap.current[newFilter]?.querySelector('button')?.click?.());
    } else if (deletedFilter) {
      removeField(deletedFilter);
    }
  };

  const commonProps = { size, disabled };
  const activeFilter = selectedFilters.some(field => filters[field]?.length) || isActiveFilter;

  const removeField = field => {
    const { [field]: deleted, ...rest } = filters;
    setFilters(rest);
  };
  const onDragEnd = ({ source, destination }) => {
    setFilters(
      rearrangeObject(
        filters,
        rearrange(
          selectedFilters,
          selectedFilters[source.index],
          destination.index,
          undefined,
          source.droppableId !== destination.droppableId
        )
      )
    );
  };

  const getElement = (field: string) =>
    function dragItem(providedDrag, snapshotDrag) {
      return (
        <Box
          ref={providedDrag.innerRef}
          {...providedDrag.draggableProps}
          {...providedDrag.dragHandleProps}
          sx={{ position: 'relative', ':hover .dragItem': { opacity: 1 }, ...providedDrag.draggableProps.style }}>
          {isDraggable && !disabled && (
            <Box
              className="dragItem"
              sx={{
                opacity: snapshotDrag.isDragging ? 1 : 0,
                ...flex.center,
                // @ts-ignore
                ...(theme.components?.MuiPaper?.styleOverrides?.rounded || {}),
                height: '100%',
                position: 'absolute',
                zIndex: theme => theme.zIndex.modal,
                pr: 1,
                backgroundImage: `linear-gradient(to right, ${theme.palette.colors.neutrals[350]} 50%, transparent )`,
              }}>
              {dragIcon}
            </Box>
          )}
          <FieldFilter
            setRef={ref => (filtersRefsMap.current[field] = ref)}
            filters={filters}
            getOptions={displayNameMapFormatter[field] ? flatArrayOfOptionsWithCustomTitle : getOptionsByField[field]}
            key={field}
            {...commonProps}
            onChange={updateFilters}
            field={field}
            isEnabled={selectedFiltersMap[field]}
            overrideExtra={overrideExtra}
            label={
              featureFlags[FeatureFlags.ShowDisplayNamesFromModel]
                ? getFieldLabel({
                    field,
                    fieldMap: fieldMeasurementsMap,
                    projectionName: activeProjName,
                  })
                : currentTypeLabels[field] || fieldMeasurementsMap[field]?.title || field
            }
            fieldType={typeLabelMap[fieldMeasurementsMap[field]?.type]}
            activeProjName={isGlobalFilter ? fields.find(f => f.value === field).mainProjId.name : activeProjName}
            queryKey={queryKey}
            getValue={displayNameMapFormatter[field]}
            isFilterExpression={isFilterExpression}
            usage={usage}
            isGlobalFilter={isGlobalFilter}
            selectIcon={
              removeFieldButton ? (
                disabled ? (
                  <Box />
                ) : (
                  <IconButton
                    sx={iconSize(16)}
                    size="small"
                    onClick={e => {
                      e.stopPropagation();
                      removeField(field);
                    }}>
                    <X />
                  </IconButton>
                )
              ) : undefined
            }
          />
        </Box>
      );
    };

  return (
    <Box
      sx={{
        ...flex.itemsCenter,
        flexWrap: 'wrap',
        gap: 1,
        '> svg': {
          mr: 1,
          color: theme => (activeFilter ? theme.palette.colors.primary[400] : theme.palette.colors.neutrals[500]),
          opacity: disabled ? 0.4 : 1,
        },
      }}>
      {(showIcon || !!Object.keys(filters).length) && (activeFilter ? filterActiveIcon : filterIcon)}
      {children}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal" isDropDisabled={!isDraggable}>
          {provided => (
            <Box
              ref={provided.innerRef}
              sx={{ ...flex.itemsCenter, flexWrap: 'wrap', gap: '5px', overflow: 'auto' }}
              {...provided.droppableProps}>
              {selectedFilters.map((field, index) => (
                <Draggable key={field} draggableId={field} index={index} isDragDisabled={selectedFilters.length === 1 || !isDraggable}>
                  {getElement(field)}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
        {!disabled && fields?.length > 0 && (
          <Select
            variant="text"
            transparent
            showSelection={false}
            {...commonProps}
            label={
              <Box sx={{ ...flex.itemsCenter, gap: 1, fontWeight: 600 }}>
                {selectedFilters.length ? (
                  moreFiltersLabel
                ) : (
                  <>
                    <Plus />
                    Add Filters
                  </>
                )}
              </Box>
            }
            onChange={onSelectedFiltersSelect}
            value={selectedFilters}
            isMultiple
            totalOptionsCount={fields.length}
            filterFunc={(options, { inputValue }) =>
              options
                .filter(
                  o => o.title.toLowerCase().includes(inputValue.toLowerCase()) || o.group?.toLowerCase().includes(inputValue.toLowerCase())
                )
                .slice(0, 400)
            }
            groupByFunc={({ group }) => group}
            options={fields}
          />
        )}
        {onClearFilters && !!selectedFilters.length && (
          <>
            <Divider orientation="vertical" sx={{ height: 16, alignSelf: 'center' }} flexItem />
            <Button variant="text" disabled={disabled} size={size} onClick={() => onClearFilters()}>
              Clear Filters
            </Button>
          </>
        )}
        {onApplyFilter && !!selectedFilters.length && (
          <Button variant="contained" size="xSmall" onClick={() => onApplyFilter()}>
            Apply
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default AvFilters;

export const getOptionsFromFields = (
  fields: FieldListObject[] = [],
  group: string | null = null,
  entity: string | null = null
): { title: string; value: string; group: string | null }[] =>
  fields
    .filter(({ name, builtIn }) => !builtIn && !blackListFields.includes(`${entity}.${name}`))
    .map(({ name }) => ({
      title: labels[`${entity}.${name}`] || prettyFieldName(name),
      value: entity ? `${entity}.${name}` : name,
      group,
    }));

export const getOptionsByField = {
  [FilterTypes.Technique]: flatArrayOfOptions,
  [FilterTypes.Asset]: flatArrayOfOptions,
  [FilterTypes.Tags]: flatArrayOfOptions,
  [FilterTypes.AssetType]: flatArrayOfOptions,
  [FilterTypes.TechniqueV2]: flatArrayOfOptions,
  [FilterTypes.AssetV2]: flatArrayOfOptions,
  [FilterTypes.TagsV2]: flatArrayOfOptions,
  [FilterTypes.AssetTypeV2]: flatArrayOfOptions,
};

// TODO: remove when cleaning newSchemaEntitiesMode
export const blackListFields = [
  'ticket.locking_key',
  'ticket.dedup_key',
  'ticket.is_fixable',
  'ticket.detection_sources',
  'ticket.finding_count',
  'ticket.asset_count',
  'ticket.vulnerabilities_count',
  'ticket.original_severity_score',
  'ticket.all_finding_count',
  'ticket.last_seen',
  'ticket.comments',
  'ticket.details',
  'ticket.activity_logs',
  'ticket.details_str',
  'ticket.severity_optimization_reasons',
  'ticket.integration_info',
  'ticket.type',
  'ticket.remediation_time',
  'ticket.previous_dedup_key',
  'finding.source',
  'finding.asset_mac',
  'finding.asset_hostname',
  'finding.type',
  'finding.asset_username',
  'finding.details',
  'finding.details_str',
  'finding.details_hash',
  'finding.previous_dedup_key',
  'finding.dedup_key_real',
  'finding.json_details',
  'finding.all_fix_versions',
  'finding.owner',
];

const labels = {
  'ticket.all_vulnerabilities_count': 'Findings',
  'ticket.all_asset_count': 'Assets',
  'ticket.integration_info.key': 'External Ticket ID',
};
