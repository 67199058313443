import React, { useMemo, useState } from 'react';
import { Box, capitalize, Collapse, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import ReactDiffViewer, { DiffMethod, ReactDiffViewerStylesOverride } from 'react-diff-viewer';
import NoDataFoundImg from '../../assets/NoDataFound.webp';
import { flex } from '../../components/AvThemeProvider';
import NoDataFound from '../../components/NoDataFound';
import AvTable from '../../components/Table/AvTable';
import { ActionButton, expandArrowColumn } from '../../components/Table/Utils';
import { useAvContext } from '../../context/AvContextProvider';
import { GetUsersInfo } from '../../hooks/getUsersInfo';
import { buildPagination, useAuditLogs } from './hooks';
import { AuditEntityType, AuditEntry } from './types';
import { ReactComponent as Copy } from '../../assets/Copy.svg';

const copyIcon = <Copy style={{ width: 20, height: 20 }} />;

interface AuditLogsProps {
  id?: string;
  entityType: AuditEntityType;
  title: React.ReactNode;
  isQueryLoading?: boolean;
  defaultData?: AuditEntry[];
  codeMode?: boolean;
  pageSize?: number;
}

const stringifyForDiff = (data: object) =>
  JSON.stringify(data || {}, null, 2).replace(/^(\s*).*\\n.*$/gm, (line, indent) => line.replace(/\\n/g, `\n${indent}`));

const AuditLogs: React.FC<AuditLogsProps> = ({ id, entityType, title, isQueryLoading, defaultData, codeMode = false, pageSize }) => {
  const { isLoading } = useAvContext();
  const { palette } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [tablePage, setTablePage] = useState(0);
  const pageVars = useMemo(() => buildPagination({ page: tablePage, pageSize }), [tablePage]);
  const onError = (msg, err) => enqueueSnackbar(`${msg}: ${err.message}`, { variant: 'error' });

  const {
    data: { rowsFromQuery = [], totalElements } = { rowsFromQuery: [], totalElements: 0 },
    isLoading: isLogsLoading,
    isError: logsError,
  } = useAuditLogs({ id, entityType, pageVars, onError });
  const rows = defaultData || rowsFromQuery;
  const { usersInfo: auditUsers } = GetUsersInfo(rows.map(({ actorId }) => actorId));
  const rowsWithActorName = rows.map(row => ({ ...row, actorName: auditUsers[row.actorId!] }));
  const loading = isLoading || isLogsLoading || isQueryLoading;

  const diffViewerStyle: ReactDiffViewerStylesOverride = {
    variables: {
      light: {
        codeFoldGutterBackground: palette.colors.neutrals[300] as string,
        codeFoldBackground: palette.colors.neutrals[200] as string,
        diffViewerBackground: palette.colors.neutrals[200] as string,
        codeFoldContentColor: palette.colors.neutrals[900] as string,
        removedBackground: palette.colors.neutrals[200] as string,
        addedBackground: palette.colors.neutrals[200] as string,
      },
    },
    wordDiff: {
      borderRadius: '8px',
      paddingLeft: '8px',
      textIndent: '-6px',
    },
    diffAdded: {
      background: palette.colors.positive[100] as string,
      color: palette.colors.positive[600] as string,
    },
    wordAdded: {
      background: palette.colors.positive[100] as string,
      color: palette.colors.positive[600] as string,
    },
    wordRemoved: {
      background: palette.colors.negative[100] as string,
      color: palette.colors.negative[600] as string,
    },
    diffRemoved: {
      background: palette.colors.negative[100] as string,
      color: palette.colors.negative[600] as string,
    },
  };

  const codeFoldMessage = (totalFoldedLines: number) => (
    <Typography
      sx={{
        '&.MuiTypography-root:hover': {
          color: theme => theme.palette.colors.primary[500],
        },
      }}>
      Expend {totalFoldedLines} Rows...
    </Typography>
  );
  const expandedRow = (row: AuditEntry, onChange, isItemSelected) => (
    <Collapse in={isItemSelected} timeout="auto" unmountOnExit>
      <Box sx={{ padding: 2 }}>
        <ReactDiffViewer
          oldValue={stringifyForDiff(row.oldRowData)}
          newValue={stringifyForDiff(row.newRowData)}
          splitView={codeMode}
          hideLineNumbers={!codeMode}
          showDiffOnly={!codeMode}
          compareMethod={DiffMethod.LINES}
          extraLinesSurroundingDiff={0}
          styles={diffViewerStyle}
          codeFoldMessageRenderer={codeFoldMessage}
        />
      </Box>
    </Collapse>
  );

  const copyOldDataInlineAction = ({ oldRowData }) =>
    ActionButton(
      'Copy Previous Version',
      () => {
        navigator.clipboard.writeText(JSON.stringify(oldRowData, null, 2));
        enqueueSnackbar(`Previous Version Copied To Clipboard`, { variant: 'success' });
      },
      copyIcon
    );

  return (
    <Box sx={{ backgroundColor: theme => theme.palette.colors.neutrals[100], ...flex.col, gap: 3 }}>
      <Box sx={{ ...flex.justifyBetweenCenter, h3: { margin: 0 } }}>
        <Box sx={{ ...flex.itemsCenter, gap: 2 }}>
          {title && (
            <Typography variant="h3" sx={{ ...flex.itemsCenter, gap: 2, whiteSpace: 'pre', margin: 0 }}>
              {title}
            </Typography>
          )}
        </Box>
      </Box>
      {rowsWithActorName.length === 0 && !loading ? (
        <NoDataFound img={NoDataFoundImg} headline="No Audit Logs Found" />
      ) : (
        <AvTable
          resizable
          rows={rowsWithActorName}
          loading={!logsError && loading}
          pagination
          paginationProps={
            defaultData ? undefined : { onPageChange: page => setTablePage(+page), totalRows: totalElements, pageNum: tablePage }
          }
          headCells={headCells}
          enableSelectionColor={false}
          expandedRowObj={{ component: expandedRow }}
          allowEdit={false}
          allowKeyboardNavigation
          inlineActions={[copyOldDataInlineAction]}
          sx={{
            '.expanded-row, .expanded-row-content, .expanded-row-content .MuiTableCell-head': {
              backgroundColor: theme => theme.palette.colors.neutrals[200],
            },
            '.expanded-row-content > .MuiTableCell-root > div': {
              borderTop: theme => `${theme.palette.colors.neutrals[300]} 1px solid`,
            },
          }}
        />
      )}
    </Box>
  );
};

export default AuditLogs;

const headCells = [
  {
    id: 'entryId',
    label: 'entryId',
    isKey: true,
    hidden: true,
  },
  expandArrowColumn(() => true),
  {
    id: 'dmlTimestamp',
    label: 'Time',
    type: 'date',
  },
  {
    id: 'operation',
    label: 'Operation',
    formatter: value => capitalize(value.toLowerCase()),
  },
  {
    id: 'actorName',
    label: 'User Name',
  },
];
