import { PeriodBehavior, RelativeDatePeriod } from '../components/DatePicker/AvDateRangePicker.constants';
import { RelativeUnit } from '../components/DatePicker/utils';

export type Filter = {
  expression?: Expression;
  and?: LogicalOperator;
  or?: LogicalOperator;
  not?: Filter;
};

export interface LogicalOperator {
  operands: Filter[];
}

export interface BoolCondition {
  equals?: string;
  empty?: IsEmpty;
}

export interface IPCondition {
  equals?: string;
  in?: StringGroup;
  notEqual?: string;
  // notIn?: StringGroup;
  gt?: string;
  lt?: string;
  gte?: string;
  lte?: string;
  empty?: IsEmpty;
  notEmpty?: IsNotEmpty;
  ipRange?: string;
  notIpRange?: string;
  cidr?: string;
  notCidr?: string;
}

export interface FixCondition {
  equals?: string;
  notEqual?: string;
  gt?: string;
  gte?: string;
  lt?: string;
  lte?: string;
  empty?: IsEmpty;
  notEmpty?: IsNotEmpty;
}

export interface ArrayCondition {
  underlying: Expression;
  resolution: 'ALL' | 'ANY' | 'COMPLETE';
}

export interface Expression {
  fieldName?: string | null;
  stringCondition?: StringCondition;
  dateCondition?: DateCondition;
  numberCondition?: NumberCondition;
  boolCondition?: BoolCondition;
  ipCondition?: IPCondition;
  fixCondition?: FixCondition;
  arrayCondition?: ArrayCondition;
}

export interface NumberGroup {
  values: number[];
}

export interface NumberCondition {
  equals?: number;
  in?: NumberGroup;
  between?: NumberGroup;
  gt?: number;
  lt?: number;
  notEqual?: number;
  not_in?: object;
  not_between?: object;
}

export interface DateCondition {
  between?: DateGroup;
  relative?: OldRelativeDate | NewRelativeDate;
}

export interface NewRelativeDate {
  value: number;
  unit: string;
  periodBehaviour: PeriodBehavior;
}
export interface OldRelativeDate {
  value: number;
  unit: string;
  direction: string;
}

export enum RelativeDirection {
  PAST = 'PAST',
  FUTURE = 'FUTURE',
}

export interface DateGroup {
  values: Date[];
}

export interface StringGroup {
  values: string[];
}

interface IsEmpty {}

interface IsNotEmpty {}

export interface StringCondition {
  equals?: string;
  in?: StringGroup;
  contains?: string;
  starts?: string;
  ends?: string;
  notEqual?: string;
  notContains?: string;
  not_in?: StringGroup;
  empty?: IsEmpty;
  notEmpty?: IsNotEmpty;
}

export enum OperatorType {
  AND = 'and',
  OR = 'or',
}

export enum StringConditionType {
  EQUALS = 'equals',
  IN = 'in',
  CONTAINS = 'contains',
  STARTS = 'starts',
  ENDS = 'ends',
  NOT_EQUAL = 'notEqual',
  EMPTY = 'empty',
  NOT_EMPTY = 'notEmpty',
  NOT_CONTAINS = 'notContains',
}

export const convertPeriodPreset = {
  [RelativeUnit.DAYS]: RelativeDatePeriod.Days,
  [RelativeUnit.WEEKS]: RelativeDatePeriod.Weeks,
  [RelativeUnit.MONTHS]: RelativeDatePeriod.Months,
};
