import { Filter } from '../../../types/filter.types';

export const GroupingRuleSetTypes = {
  Finding: 'uber_findings',
  Alert: 'uber_alerts',
  Ticket: 'tickets',
  Incident: 'incidents',
};

export const GroupingRuleToTypes = {
  [GroupingRuleSetTypes.Finding]: 'tickets',
  [GroupingRuleSetTypes.Alert]: 'incidents',
};

export const SubAutoComplete = {
  [GroupingRuleSetTypes.Alert]: 'uber_alert_actors',
};

export const DEFAULT_RULE_NAME = 'Default';

export const NEW_RULE_SET_ID = 'create';

export const NEW_RULE_ID = 'create-rule';

type FieldGrouping = {
  fields: string[];
};

type Grouping = {
  fieldGrouping: FieldGrouping;
};

export type GroupingRule = {
  name: string;
  ruleOrder?: number;
  condition?: Filter;
  grouping?: Grouping;
  id?: string;
  createdAt?: string;
  createdUserId?: string;
  updatedAt?: string;
  updatedUserId?: string;
  entityTitleFormat: string;
  defaultFallbackRule?: boolean;
};

export type GroupingRulesSet = {
  createdAt: string;
  createdUserId: string;
  entityType: string;
  name: string;
  active: boolean;
  rules: GroupingRule[];
  id: string;
  updatedUserId: string;
  updatedAt: string;
};
