import React, { createContext, useContext, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import * as Auth from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  fetchSession,
  getAccessTokenTimeout,
  getAccountTokenFromLS,
  logoutFromAvalor,
  newLoginFlow,
  overrideAccountIdUserAttribute,
  removeCognitoLS,
  setDDUser,
  shouldSkipUILogin,
  signIn,
  updateTokenInLS,
} from '../utils/auth.utils';
import { isExportMode } from '../utils/exportDashboards.utils';
import { getBranchPrefix, setAccountFromLS } from '../utils/router.utils';
import { shouldRecordSession } from '../utils/rum.utils';
import { noop } from '../utils/Utils';

type AuthStateType = {
  user: { userName: string; email: string; userId?: string; roleId?: number; permissions?: string; abac?: string; accountId?: string };
  isAuthenticated: boolean;
};

const authContext = createContext<Partial<AuthContextType>>({});
// eslint-disable-next-line react/prop-types
export function ProvideAuth({ children, isCurrentlyLoggingIn }) {
  const auth = useProvideAuth(isCurrentlyLoggingIn);
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
export const useAuth = () => useContext(authContext);
const useProvideAuth = (isCurrentlyLoggingIn: boolean = false) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(!shouldSkipUILogin());
  const [params] = useSearchParams();
  const location = useLocation();

  const [authState, setAuthState] = useState<AuthStateType>({
    user: { userName: '', email: '' },
    isAuthenticated: shouldSkipUILogin(),
  });
  const localAuth = import.meta.env.VITE_ENDPOINT_FOR_TOKEN;

  const useLocalAccessToken = () =>
    useMutation<string, Error, { accountId: string; email: string }>({
      mutationKey: ['local-token'],
      mutationFn: ({ accountId, email }) =>
        fetch(`${localAuth}?email=${email}&accountId=${accountId}`).then(async res => {
          const result = await res.text();
          if (res.ok) {
            return result;
          }
          throw new Error('No access Token received');
        }),
    });
  const { mutateAsync: getLocalToken } = useLocalAccessToken();

  const getUser = async (retry: boolean = false) => {
    try {
      const shouldLogin = !localStorage.getItem('passedLogin');
      const hasCodeInURL = params.get('code');
      // fetch session doesn't throw an error if the user is not authenticated, but it returns the idToken as undefined
      const result = await fetchSession({ forceRefresh: false, firstLogin: true });
      if (result.tokens?.idToken?.payload) {
        const { payload } = result.tokens.idToken;
        setAuthState({
          user: setUserFromTokenPayload(payload as TokenUser),
          isAuthenticated: true,
        });
        setIsLoading(false);
      } else if (retry) {
        // if after the retry there is still no token, logout the user
        setAuthState({ user: { userName: '', email: '' }, isAuthenticated: false });
        console.error(`[authentication error]: No Token after retry: ${JSON.stringify(result)}`);
        logoutFromAvalor({ logout: removeCognitoLS, loginWithRedirect: noop, redirectBack: false });
        window.location.reload();
      } else {
        setAuthState({ user: { userName: '', email: '' }, isAuthenticated: false });
        if (shouldLogin) {
          // if there is no passedLogin in the LS, that means the user hasn't logged in
          // redirects the user to the relevant authentication method (Cognito/IDP)
          localStorage.setItem('passedLogin', 'true');
          await signIn();
        } else if (!hasCodeInURL) {
          console.error(`[authentication error]: No Token received after redirect to login: ${JSON.stringify(result)}`);
          // if the user doesn't have code in the url, but has the passedLogin in LS, it might mean the user
          // pressed back in the IDP page. In this case, we would like to logout the user, but sometimes there
          // is some race condition here, so we first want to try get the token again before logout
          setTimeout(() => getUser(true), 2000);
        } else {
          // the user is in the middle of a login process and therefore no action needed here, the Hub.listen
          // will trigger this function again when the login will end
          console.error(`[authentication error]: No action for the user`);
        }
      }
    } catch (error) {
      console.error(`[authentication error]: ${error}`);
      setAuthState({ user: { userName: '', email: '' }, isAuthenticated: false });
      setIsLoading(false);
    }
  };

  const getUserNew = async (retry: boolean = false) => {
    const hasCodeInTheUrl = params.get('code');
    if (isCurrentlyLoggingIn) {
      return;
    }
    try {
      // throws an error if the user is not authenticated
      await Auth.getCurrentUser();
      // get the token from the session, if the token is empty set isAuthenticated to false and isLoading to false => logout from app.tsx
      const { idToken } = (await fetchSession({ forceRefresh: retry, firstLogin: true })).tokens ?? {};
      setAuthState({
        user: idToken ? setUserFromTokenPayload(idToken.payload as TokenUser) : { userName: '', email: '' },
        isAuthenticated: !!idToken,
      });
      setIsLoading(false);
    } catch (error) {
      console.error(`[authentication error]: No user is found. retry ${retry}: ${error}`);
      setAuthState({ user: { userName: '', email: '' }, isAuthenticated: false });
      if (!hasCodeInTheUrl) {
        if (retry) {
          // if after the retry there is still no token, logout the user
          setIsLoading(false);
          console.error(`[authentication error]: No Token after retry: ${JSON.stringify(error)}`);
          logoutFromAvalor({ logout: removeCognitoLS, loginWithRedirect: noop, redirectBack: false });
          window.location.reload();
        } else {
          // if the user doesn't have code in the url it might mean we have some race condition and the user is
          // in the middle of a login process. try again to get the token with setTimeout to support the e2e user
          setTimeout(() => getUserNew(true), 2000);
        }
      }
      // if there is code param, lets wait for Hub.listen to get the signInWithRedirect event
    }
  };

  const getUserFunction = () => {
    if (newLoginFlow()) {
      getUserNew();
    } else {
      getUser();
    }
  };

  const setUserFromTokenPayload = (tokenPayload: TokenUser) => ({
    email: tokenPayload.email as string,
    userId: tokenPayload.avalor_user_id as string,
    roleId: tokenPayload.role_id as number,
    accountId: tokenPayload.account_id as string,
    abac: tokenPayload.abac_filters as string,
    permissions: tokenPayload.scope as string,
    userName: tokenPayload.avalor_user_name as string,
    roleName: tokenPayload.role_name as string,
  });

  useEffect(() => {
    if (shouldSkipUILogin()) {
      return () => {};
    }
    const unsubscribe = Hub.listen('auth', ({ payload }) => {
      const errorText = JSON.stringify((payload as any)?.data?.error?.message);
      const underlyingError = JSON.stringify((payload as any)?.data?.error?.underlyingError);
      switch (payload.event) {
        case 'customOAuthState':
          setTimeout(() => navigate(decodeURIComponent(payload.data)));
          break;
        case 'signInWithRedirect':
          getUserFunction();
          break;
        case 'signInWithRedirect_failure':
          logoutFromAvalor({ logout: removeCognitoLS, loginWithRedirect: noop, redirectBack: false });
          navigate('');
          console.error(`[authentication error]: LOGIN FAIL: ${errorText} ${underlyingError}`);
          window.location.reload();
          break;
        case 'tokenRefresh_failure':
          logoutFromAvalor({ logout: removeCognitoLS, loginWithRedirect: noop, redirectBack: false });
          console.error(`[authentication error]: REFRESH TOKEN FAIL: ${errorText} ${underlyingError}`);
          window.location.reload();
          break;
        default:
          break;
      }
    });
    getUserFunction();

    return unsubscribe;
  }, []);

  const updateUserAuthState = token => {
    const formattedUserMetadata = setUserFromTokenPayload(token);
    setAuthState({ user: formattedUserMetadata, isAuthenticated: !!token });
    if (shouldRecordSession && !isExportMode()) {
      setDDUser({ ...formattedUserMetadata, role: token.role_id });
    }
  };

  const updateTokenForUser = async (accountId, attempts = 0) => {
    if (localAuth) {
      const token = await getLocalToken({ email: authState.user.email, accountId });
      const parsedToken = parseJwt(token);
      const tokenAccountId = parsedToken.account_id;
      if (!accountId) {
        navigate(tokenAccountId);
      }
      updateTokenInLS({ accountId: tokenAccountId, token });
      updateUserAuthState(parsedToken);
      return token;
    }
    if (shouldSkipUILogin()) {
      const token = getAccountTokenFromLS(accountId);
      if (token) {
        updateUserAuthState(parseJwt(token));
      }
      return token;
    }
    if (!accountId || getBranchPrefix()) {
      const kb = location.pathname.includes('/zendesk/token');
      const { token, res } = await getTokenWhenNoAccount();
      const accountId = res.tokens?.idToken?.payload.account_id;
      if (accountId && !kb) {
        if (getBranchPrefix()) {
          setAccountFromLS(accountId);
        } else {
          navigate(accountId as string);
        }
      }

      updateTokenInLS({ accountId, token });
      updateUserAuthState(res.tokens?.idToken?.payload as TokenUser);
      return token;
    }
    // if in local storage there is a token for the account, and it is not expired for more than 4 minutes
    // return the token to remove the API calls
    const oldToken = getAccountTokenFromLS(accountId);
    const exp = oldToken ? Auth.decodeJWT(oldToken)?.payload?.exp : undefined;
    const timeout = getAccessTokenTimeout() / 1000 - 30; // a minute before the actual expiration
    if (exp && exp > Date.now() / 1000 + timeout) {
      updateUserAuthState(Auth.decodeJWT(oldToken).payload as TokenUser);
      return oldToken;
    }
    await overrideAccountIdUserAttribute(accountId, { logout: signOut, loginWithRedirect: signIn });
    const res = await fetchSession({});
    const token = getToken(res);
    if (res.tokens?.idToken?.payload.account_id === accountId && token) {
      updateTokenInLS({ accountId, token });
      updateUserAuthState(res.tokens?.idToken?.payload as TokenUser);
      return token;
    }
    if (attempts < 1) {
      console.debug(
        `${attempts + 1} attempt to get access token for the account ${accountId} has failed. recieved token for account: ${res.tokens
          ?.idToken?.payload.account_id}`,
        res.tokens?.idToken?.payload.avalor_user_id
      );
      const token = await updateTokenForUser(accountId, attempts + 1);
      return token;
    }
    if (res.tokens?.idToken?.payload.account_id !== res.tokens?.idToken?.payload.default_account_id) {
      if (attempts > 3) {
        console.error(
          `[authentication error]: attempt ${attempts} token is not valid for account ${accountId} or the default account, token account ${res.tokens?.idToken?.payload.account_id}`
        );
      }
      const newToken = await updateTokenForUser(accountId, attempts + 1);
      return newToken;
    }
    if ((res as any)?.error) {
      if (attempts > 1) {
        console.error(`[authentication error]: attempt ${attempts} for getting token with error ${(res as any)?.error}`);
        return null;
      }
      const tokenNoNetwork = await updateTokenForUser(accountId, attempts + 1);
      return tokenNoNetwork;
    }
    if (!res.tokens?.idToken) {
      console.error(`[authentication error]: token received empty for account ${accountId} response: ${JSON.stringify(res)}`);
      window.location.reload();
      return null;
    }
    console.debug('switch for default account for user', res.tokens?.idToken?.payload.avalor_user_id);
    const defaultAccount = res.tokens?.idToken?.payload.default_account_id;
    enqueueSnackbar('No Permissions To Requested Account', { variant: 'error' });
    await overrideAccountIdUserAttribute(defaultAccount, { logout: signOut, loginWithRedirect: signIn });
    updateTokenInLS({ accountId: defaultAccount, token });
    updateUserAuthState(res.tokens?.idToken?.payload as TokenUser);
    navigate(defaultAccount as string);
    return token;
  };

  const getToken = res => {
    const token = res.tokens?.idToken;
    if (token) {
      return token.toString();
    }
    if ((res as any)?.error) {
      console.error('[authentication error]: No Token received', JSON.stringify(token));
    }
    return undefined;
  };

  const getTokenWhenNoAccount = async () => {
    const res = await fetchSession({ forceRefresh: false });
    const token = getToken(res);
    if (token) {
      return { token, res };
    }
    const refreshRes = await fetchSession({});
    return { token: getToken(refreshRes), res: refreshRes };
  };

  const parseJwt = token => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      setAuthState({ user: { userName: '', email: '' }, isAuthenticated: false });
      setIsLoading(true);
      return { success: true, message: '' };
    } catch (error) {
      return {
        success: false,
        message: 'LOGOUT FAIL',
      };
    }
  };

  const getAccessToken = accountId => updateTokenForUser(accountId);

  return {
    isLoading,
    ...authState,
    getAccessToken,
    signIn,
    signOut,
  };
};

type AuthContextType = {
  isLoading: boolean;
  isAuthenticated: boolean;
  user: User;
  getAccessToken: (accountId: string) => Promise<string>;
  signIn: (state?) => void;
  signOut: () => Promise<AuthResponse>;
};

type User = { userName: string; email: string; userId?: string; roleId?: number; permissions?: string; abac?: string; accountId?: string };
type TokenUser = {
  email: string;
  avalor_user_id: string;
  role_id: number;
  account_id: string;
  abac_filters: string;
  scope: string;
  avalor_user_name: string;
  role_name: string;
};

type AuthResponse = {
  success: boolean;
  message: string;
};
