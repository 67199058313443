import React from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../../components/Permissions';
import { useAvContext } from '../../context/AvContextProvider';
import { APP_PATHS, PAGE_PATHS } from '../../types';
import avLazy from '../../utils/AvLazy';
import DataUnificationRoute from '../DataUnificationRoute';
import MeasurementsRoute from '../MeasurementsRoute';
import ModelManagementRoute from '../ModelManagementRoute';
import NotificationTargetsRoute from '../NotificationTargetsRoute';
import SourcesRoute from '../SourcesRoute';

const Workflows = avLazy(() => import('../../views/Workflows/Workflows'));
const Aliases = avLazy(() => import('../../views/Aliases/Aliases'));

const Platform = () => {
  const {
    userPermissions: { hasAllowedPermission },
  } = useAvContext();
  const workflowsPath = PAGE_PATHS.WORKFLOWS;
  const aliasesPath = PAGE_PATHS.ALIASES;
  return (
    <Route path={APP_PATHS.PLATFORM}>
      {ModelManagementRoute()}
      {SourcesRoute()}
      {NotificationTargetsRoute()}
      {MeasurementsRoute()}
      {DataUnificationRoute()}

      <Route
        path={workflowsPath}
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path: workflowsPath })}>
            <Workflows />
          </ProtectedRoute>
        }
      />
      <Route
        path={aliasesPath}
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path: aliasesPath })}>
            <Aliases />
          </ProtectedRoute>
        }
      />
    </Route>
  );
};
export default Platform;
