import React from 'react';
import { Route } from 'react-router-dom';
import { APP_PATHS } from '../../types';
import { entityViewConfig } from '../../utils/entityViewConfig';
import CustomDashboardsRoute from '../CustomDashboardsRoute';
import DetectionSettings from '../DetectionSettings';
import ExploreRoute from '../ExploreRoute';
import GeneralEntityRoute from '../GeneralEntityRoute';
import GlobalScriptsRoute from '../GlobalScriptsRoute';
import LookupTableRoute from '../LookupTableRoute';
import RulesRoute from '../RulesRoute';

const Detections = () => (
  <Route path={APP_PATHS.DETECTIONS}>
    {GeneralEntityRoute(entityViewConfig.Incident.projectionName)}
    {GeneralEntityRoute(entityViewConfig.Alert.projectionName)}
    {RulesRoute()}
    {GlobalScriptsRoute()}
    {LookupTableRoute()}
    {ExploreRoute(entityViewConfig.Incident.projectionName, APP_PATHS.DETECTIONS)}
    {DetectionSettings(APP_PATHS.DETECTIONS)}
    {CustomDashboardsRoute()}
  </Route>
);
export default Detections;
