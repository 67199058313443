import React, { Component } from 'react';
import { Box, Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { noop } from '../utils/Utils';
import Loading from './Loading.tsx';
import FatalError from '../assets/FatalError.svg';
import NotFoundSVG from '../assets/NotFound.svg';

const notFoundStyle = {
  '@keyframes fadeIn': {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
  },
  animation: 'fadeIn 0.5s',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  gap: '24px',
  backgroundColor: theme => theme.palette.background.default,
  h3: {
    color: theme => theme.palette.colors.neutrals[600],
    marginBottom: 0,
  },
  button: {
    color: theme => theme.palette.primary.main,
    fontWeight: 600,
  },
};
// TODO merge with NoDataFound component
export function NotFound({ text, image, buttonLabel, onclick }) {
  const navigate = useNavigate();
  const navi = () => navigate('/');
  return (
    <main>
      <Box sx={notFoundStyle}>
        <img src={image} alt="not found" style={{ marginBottom: '56px' }} />
        <Typography variant="h3">{text}</Typography>
        <Button variant="text" onClick={onclick || navi}>
          {buttonLabel}
        </Button>
      </Box>
    </main>
  );
}
NotFound.propTypes = {
  text: PropTypes.string,
  image: PropTypes.string,
  buttonLabel: PropTypes.string,
  onclick: PropTypes.func,
};
NotFound.defaultProps = {
  text: 'Page Not Found',
  image: NotFoundSVG,
  buttonLabel: 'Return to Homepage',
  onclick: undefined,
};

class ErrorBoundaryClass extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, hasModuleError: false };
  }

  componentDidMount() {
    const numberOfTimes = +(localStorage.getItem('last_catched_route') || 0);
    if (numberOfTimes) {
      console.error('[temporary testing error]: Failed to fetch dynamically imported module');
    }
    localStorage.setItem('last_catched_route', `0`);
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;
    const { hasError, hasModuleError } = prevState;
    if ((hasError || hasModuleError) && prevProps.location !== location) {
      this.setState(prev => ({ ...prev, hasError: false, hasModuleError: false }));
    }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.error(error);
    const hasModuleError = error?.message?.includes('Failed to fetch dynamically imported module');
    return { hasError: !hasModuleError, hasModuleError };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
    this.props.onError();
  }

  render() {
    const { hasError, hasModuleError } = this.state;
    const { children, fallbackComponent } = this.props;
    const onErrorRender = fallbackComponent || <NotFound text="Something Went Wrong..." image={FatalError} />;

    if (hasModuleError) {
      // checks how many times the error occurred and writes the updated amount to the local storage.
      // it performs cache busting by adding # to the end of the url and by that forces the browser to refetch the file.
      const numberOfTimes = +(localStorage.getItem('last_catched_route') || 0);
      if (numberOfTimes < 5) {
        setTimeout(
          () => {
            localStorage.setItem('last_catched_route', `${numberOfTimes + 1}`);
            window.location.href = `${window.location.href}#`;
            window.location.reload();
          },
          2 ** numberOfTimes * 500
        );
        return <Loading />;
      }
      return onErrorRender;
    }

    if (hasError) {
      return onErrorRender;
    }

    return children;
  }
}
ErrorBoundaryClass.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape().isRequired,
  fallbackComponent: PropTypes.node,
  onError: PropTypes.func,
};

ErrorBoundaryClass.defaultProps = {
  fallbackComponent: undefined,
  onError: noop,
};

export function ErrorBoundary({ children, ...props }) {
  const location = useLocation();
  return (
    <ErrorBoundaryClass location={location} {...props}>
      {children}
    </ErrorBoundaryClass>
  );
}
ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  fallbackComponent: PropTypes.node,
};

ErrorBoundary.defaultProps = {
  fallbackComponent: undefined,
};
