import { datadogRum } from '@datadog/browser-rum';
import { Amplify } from 'aws-amplify';
import * as Auth from 'aws-amplify/auth';
import { useIdleTimer } from 'react-idle-timer';

export const shouldSkipUILogin = () => import.meta.env.VITE_SKIP_AUTH === 'true';
export const shouldNotLogoutOnAttributeChange = () => import.meta.env.VITE_ATTRIBUTES_CHANGE === 'true';
export const newLoginFlow = () => import.meta.env.VITE_NEW_LOGIN_FLOW === 'true';
export const loginFix = () => import.meta.env.VITE_FIX_LOGIN === 'true';

export const accessTokenByAccountId = 'accessTokenByAccountId';
const defaultIdleTimeout = -1; // no idle timeout was set, do not logout the user

// eslint-disable-next-line consistent-return
export const overrideAccountIdUserAttribute = async (accountId, { logout, loginWithRedirect }, attempts = 0) => {
  try {
    await Auth.updateUserAttributes({
      userAttributes: {
        'custom:web_account_id': accountId,
      },
    });
  } catch (error) {
    if (attempts < 1) {
      const { accessToken } = (await fetchSession({})).tokens ?? {};
      if (accessToken) {
        await overrideAccountIdUserAttribute(accountId, { logout, loginWithRedirect }, attempts + 1);
      } else {
        console.debug('no token for updating the attribute');
        return undefined;
      }
    } else {
      console.error(
        `[authentication error]: Error changing account ID user attribute to: ${accountId} after 2 attempts ${JSON.stringify(
          (error as any).message || error
        )}`
      );
      if (!shouldNotLogoutOnAttributeChange()) {
        logoutFromAvalor(logout);
      }
    }
  }
};

export const fetchSession = async ({ forceRefresh = true, firstLogin = false }: { forceRefresh?: boolean; firstLogin?: boolean }) => {
  try {
    const res = await Auth.fetchAuthSession({ forceRefresh });
    if (!res.tokens?.idToken && !firstLogin) {
      console.error(
        `[authentication error]: token is empty after refresh. response: ${JSON.stringify(res)}, forceRefresh: ${forceRefresh}`
      );
    }
    return res;
  } catch (error) {
    console.error(`[authentication error]: Fetch session error: ${(error as any).message} ${(error as any).underlyingError} `);
    return { error: (error as any).underlyingError, tokens: { accessToken: undefined, idToken: undefined } };
  }
};

export const logoutFromAvalor = ({ logout, loginWithRedirect, redirectBack }) => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('accountId');
  localStorage.removeItem('passedLogin');

  if (redirectBack) {
    loginWithRedirect(window.location.pathname + window.location.search);
  } else {
    localStorage.removeItem(accessTokenByAccountId);
    localStorage.removeItem('authConfig');
    logout();
  }
};

export const getAccountTokenFromLS = accountId => JSON.parse(localStorage.getItem(accessTokenByAccountId) || '{}')[accountId];

export const updateTokenInLS = ({ token, accountId }) => {
  const accessTokenByAccountIdValue = JSON.parse(localStorage.getItem(accessTokenByAccountId) || '{}');
  localStorage.setItem(accessTokenByAccountId, JSON.stringify({ ...accessTokenByAccountIdValue, [accountId]: token }));
};

export const useLogoutWhenUserIsIdle = ({ logout }) => {
  const { shouldLogout, timeout } = logoutOnIdle();
  const onIdle = () => {
    if (shouldLogout) {
      logout();
    }
  };
  useIdleTimer({
    timeout,
    onIdle,
    startOnMount: true,
    crossTab: true,
    syncTimers: 100,
    name: 'logoutInActivity',
    disabled: !shouldLogout,
  });
};

const logoutOnIdle = () => {
  const timeout = getTimeoutFromAuthConfig({ key: 'logoutInactivityTimeout', defaultValue: 1 }); // for when there is no auth config
  const shouldLogout = timeout !== defaultIdleTimeout;
  return { shouldLogout, timeout: shouldLogout ? timeout : 10000 };
};

export const useUserIsActive = ({ setIsNotIdle, accessTokenTimeout }) => {
  const timeout = accessTokenTimeout - 10000; // 10 seconds before the token expires
  useIdleTimer({
    timeout,
    onIdle: () => {
      setIsNotIdle(false);
    },
    onActive: () => {
      setIsNotIdle(true);
    },
  });
};

export const getAccessTokenTimeout = () => {
  const timeoutInSeconds = getTimeoutFromAuthConfig({ key: 'accessTokenTimeoutInMinutes', defaultValue: 5 }); // default is 5 minutes
  return timeoutInSeconds - 30000; // remove half minute to prevent race condition
};

export const getTimeoutFromAuthConfig = ({ key, defaultValue }: { key: string; defaultValue: number }) => {
  const authConfig = JSON.parse(localStorage.getItem('authConfig') || 'null');
  const timeoutValue = !authConfig ? undefined : authConfig[key];
  // if there is timeout value of -1, it means the timeout is disabled when key is logoutInactivityTimeout
  if (timeoutValue === defaultIdleTimeout) {
    return defaultIdleTimeout;
  }
  return timeoutValue ? timeoutValue * 60000 : defaultValue * 60000; // timeout value and default value are in minutes
};

export const setDDUser = user =>
  datadogRum.setUser({
    ...datadogRum.getUser(),
    userId: user.userId,
    name: user.userName,
    email: user.email,
    roleId: user.roleId,
    roleName: user.roleName,
    accountId: user.accountId,
    role: user.role,
  });

export const removeCognitoLS = () => {
  const cognitoLSValues = Object.keys(localStorage).filter(key => /^CognitoIdentityServiceProvider(.*)$/.test(key));
  for (let i = 0; i < cognitoLSValues.length; i++) {
    const key = cognitoLSValues[i];
    localStorage.removeItem(key);
  }
};

export const signIn = async (state?) => {
  const authConfig = JSON.parse(localStorage.getItem('authConfig') || '{}');
  const signInDetails: any = {
    customState: `${encodeURIComponent(state || window.location.pathname + window.location.search)}`,
  };
  if (authConfig.userPoolIdentityProviderName) {
    signInDetails.provider = { custom: authConfig.userPoolIdentityProviderName };
  }
  return Auth.signInWithRedirect(signInDetails).catch(e => console.error(`[authentication error]: ${e}`));
};

export const configureAmplify = authConfig => {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolClientId: authConfig.userPoolWebClientId,
        userPoolId: authConfig.userPoolId,
        loginWith: {
          oauth: {
            domain: authConfig.userPoolDomain,
            scopes: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: [`${window.location.origin}`],
            redirectSignOut: [`${window.location.origin}`],
            responseType: 'code',
          },
        },
      },
    },
  });
};
