import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { gql } from '@apollo/client';
import { Alert, alpha, Box, Button, Divider, useTheme } from '@mui/material';
import { keepPreviousData, useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import AvDialog from '../../components/AvDialog';
import { flex } from '../../components/AvThemeProvider';
import SavedViewPopper, { useSaveView } from '../../components/SavedViewPopper';
import SavedViewSelect from '../../components/SavedViewSelect';
import TextInput from '../../components/TextInput';
import { useAvContext } from '../../context/AvContextProvider';
import { NotificationContext } from '../../context/AvSnackBarProvider';
import { DescriptorType, EntityTypeID } from '../../context/context.type';
import { WorkflowType } from '../../context/SnackBar.types';
import { GetUsersInfo } from '../../hooks/getUsersInfo';
import { useCustomSearchParams } from '../../hooks/UseCustomSearchParams';
import useQuerySql from '../../hooks/useQuerySql';
import { FeatureFlags, Permission, PermissionEntitiesNames } from '../../types';
import { ScreenType } from '../../types/savedViews.types';
import { cleanEmptyFilters, filterToExpression } from '../../utils/filterUtils';
import { deserializeSortItem, isDeepEqual } from '../../utils/Utils';
import { useGetDisplayName, useGetFieldType } from '../CustomDashboards/hooks';
import { getHeadCellsWithSort, queryOrderBy, useBuildWhereClause, useUserViews } from '../Tickets/hooks';
import { defaultColumnsForEntity, defaultFiltersForEntity } from './Entities.constants';
import { ReactComponent as Delete } from '../../assets/Delete.svg';
import { ReactComponent as Transition } from '../../assets/Transition.svg';

const defaultNumberColumns = 7;

const useDataForEntity = ({ defaultFilter, rowLimit, setSelectedViewChanged }) => {
  const theme: any = useTheme();
  const [searchValue, setSearchValue] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const {
    featureFlags,
    accountEntities: { fieldTypeMap, aggProjs },
  } = useAvContext();
  const entitiesOptions = useMemo(() => Object.values(aggProjs), []);
  const [filters, setFilters] = useCustomSearchParams({
    defaultFilter,
    dateType: [],
    shouldBeArray: () => true,
    contextSearchKey: 'entities',
  });
  const activeEntityId = filters.activeEntityId[0];
  const activeProj = Object.values(aggProjs).find(({ entityTypeId: { name } }) => name === activeEntityId)!.projId;
  const activeProjName = Object.values(aggProjs).find(({ entityTypeId: { name } }) => name === activeEntityId)!.projId.name;
  const page = +filters.tablePage[0];
  const orderBy = filters.orderBy
    .map(deserializeSortItem)
    .filter(({ property }) => !filters.headCells || filters.headCells.includes(property));
  const filterOptions = aggProjs[activeProjName].fieldList.INTERACTIVE;
  const investigationMode = filters.investigationMode[0];
  const boolInvestigationMode = investigationMode === 'true';
  const { headCells: h, ...queryFilters } = filters;
  const getHeadCellType = useGetFieldType(activeProjName);
  const getFieldDisplayName = useGetDisplayName(activeProjName);

  const investigationHeadCell = (entityFieldKey, displayName?) => ({
    id: `${activeProjName}.sources.${entityFieldKey}`,
    label: (
      <>
        <Transition
          style={{
            position: 'absolute',
            right: getHeadCellType({ entityFieldKey }) === 'number' ? -42 : -34,
            color: theme.palette.colors.primary[400],
          }}
        />
        {`Original ${getFieldDisplayName(entityFieldKey) || displayName}`}
      </>
    ),
    ...rawColumnStyle,
  });

  const initHeadCells = projName => [
    ...aggProjs[projName].fields.reduce<any[]>(
      (acc, { name: entityFieldKey, displayName, id }, currentIndex) => [
        ...acc,
        ...(boolInvestigationMode
          ? [
              {
                hidden: defaultColumnsForEntity[activeProjName]
                  ? !defaultColumnsForEntity[activeProjName].includes(entityFieldKey)
                  : currentIndex >= defaultNumberColumns,
                ...investigationHeadCell(entityFieldKey, displayName),
              },
            ]
          : []),
        {
          hidden: defaultColumnsForEntity[activeProjName]
            ? !defaultColumnsForEntity[activeProjName].includes(entityFieldKey)
            : currentIndex >= defaultNumberColumns,
          id: id || `${activeProjName}.${entityFieldKey}`,
          label: getFieldDisplayName(entityFieldKey) || displayName,
          isKey: entityFieldKey === '_key',
        },
      ],
      []
    ),
  ];

  const headCells = useMemo(() => {
    const initialHeadCells = initHeadCells(activeProjName);
    return getHeadCellsWithSort(
      filters.headCells
        ? [
            ...filters.headCells.flatMap(id => {
              const initCell = initialHeadCells.find(h => h.id === id);
              if (!initCell) {
                const error = `[Setup Error]: unknown column ${id}`;
                console.error(error);
                if (featureFlags[FeatureFlags.ShowServerErrorInToast]) {
                  enqueueSnackbar(error, { variant: 'error' });
                }
              }
              const cell = initCell || { id, label: 'Unknown' };
              return boolInvestigationMode
                ? [
                    { ...cell, hidden: false, ...investigationHeadCell(cell.id.substring(cell.id.indexOf('.') + 1)) },
                    { ...cell, hidden: false },
                  ]
                : [{ ...cell, hidden: false }];
            }),
            ...initialHeadCells
              .filter(h => !filters.headCells.includes(h.id) && !h.id.includes('.sources.'))
              .map(h => ({ ...h, hidden: true })),
          ]
        : initialHeadCells,
      orderBy
    );
  }, [filters.headCells, filters.orderBy, activeProjName, boolInvestigationMode]);
  const updateFilters = useCallback(
    (key, val, isPageChange = false) => {
      const newFilters = { ...filters, [key]: val, ...(isPageChange ? {} : { tablePage: ['0'] }) };
      setFilters(newFilters);
      setSelectedViewChanged(true);
    },
    [filters, setFilters]
  );

  const isFieldText = aggProjs[activeProjName].fields.reduce(
    (obj, { name }) => ({
      ...obj,
      [`${aggProjs[activeProjName].pathAlias}.${name}`]:
        fieldTypeMap[`${aggProjs[activeProjName].pathAlias}.${name}`] === DescriptorType.TYPE_STRING,
    }),
    {}
  );
  const textColumnsShown = initHeadCells(activeProjName)
    .filter(headCell => isFieldText[headCell.id] && !headCell.hidden)
    .map(headCell => headCell.id);
  const where = useBuildWhereClause({ filters: queryFilters });
  const sqlFields = headCells
    .filter(headCell => !headCell.hidden && !headCell.id.includes('tickets') && !headCell.id.includes('incidents'))
    .map(headCell => headCell.id);
  const allSQL = `SELECT ${sqlFields}
                  FROM ${activeProjName} ${where} ${queryOrderBy(orderBy, headCells)}`;
  const sql = `${allSQL} OFFSET ${page * rowLimit} LIMIT ${rowLimit}`;
  const {
    data: rows,
    isLoading,
    isRefetching,
    totals: count,
  } = useQuerySql({
    key: 'entityExplorer',
    sql,
    showTotals: true,
    options: { placeholderData: keepPreviousData },
  });
  return {
    filters,
    setFilters,
    entitiesOptions,
    searchValue,
    setSearchValue,
    updateFilters,
    headCells,
    page,
    textColumnsShown,
    activeProjName,
    activeProj,
    rows,
    isLoading,
    isRefetching,
    count,
    allSQL,
    initHeadCells,
    filterOptions,
    orderBy,
    investigationMode,
    aggProjs,
  };
};
export default useDataForEntity;

export const useSavedViews = ({
  viewScreenType,
  filters,
  setFilters,
  defaultFilters = {},
  activeProjName,
  controlledSelectedViewChanged = false,
  setControlledSelectedViewChanged,
  defaultEnabled = true,
}: {
  viewScreenType: ScreenType;
  filters: any;
  setFilters: any;
  defaultFilters?: any;
  activeProjName?: string;
  controlledSelectedViewChanged?: boolean;
  setControlledSelectedViewChanged?: any;
  defaultEnabled?: boolean;
}) => {
  const {
    user,
    userPermissions: { hasAllowedPermissionToResource },
    accountEntities: { aggProjs, fieldMap },
  } = useAvContext();
  const saveView = useSaveView();
  const { enqueueSnackbar } = useSnackbar();
  const selectViewRef = useRef<Element>();
  const saveAsViewButtonRef = useRef<Element>();

  const [openEditViewPopper, setOpenEditViewPopper] = useState(false);
  const [openSaveViewPopper, setOpenSaveViewPopper] = useState(false);
  const [selectedViewChanged, setSelectedViewChanged] = useState(false);

  const { userViews = [], refetch: viewsRefetch } = useUserViews(viewScreenType);
  const viewsCreatorsIds = useMemo(() => userViews.filter(v => v.createdByUserId).map(v => v.createdByUserId), [userViews]);
  const { usersInfo } = GetUsersInfo([...new Set([...viewsCreatorsIds, user.userId])]);

  const replaceStateProperty = (obj, stateField, newStateField) => {
    const { config, ...view } = obj;
    const { [stateField]: val, activeProjName: oldActiveProjName, ...partialConfig } = config;
    const newConfig = {
      ...partialConfig,
      ...(fieldMap[newStateField].visibilityConfig.config.hidden ? {} : { [newStateField]: val }),
      ...(activeProjName ? { activeEntityId: [aggProjs[activeProjName].entityTypeId.name] } : {}),
    };
    return { ...view, config: newConfig };
  };
  const customActiveView = useMemo(
    () =>
      userViews.map(v =>
        v.global && v.name === 'Active' ? replaceStateProperty(v, 'finding.state', `${aggProjs[activeProjName!].pathAlias}.state`) : v
      ),
    [userViews, activeProjName]
  );

  const viewIdToViewInfo = useMemo(
    () => customActiveView.reduce((obj, view) => ({ ...obj, [view.id]: { ...view, config: { ...view.config, viewId: [view.id] } } }), {}),
    [userViews, activeProjName]
  );

  const defaultView = useMemo(
    () => customActiveView.find(v => v.defaultView || v.accountDefaultView || (v.global && v.name === 'Active')) || [],
    [customActiveView]
  );

  const isDefaultFilter = isDeepEqual(defaultFilters, filters);
  const selectedViewIdArray = filters.viewId || (!isDefaultFilter ? [] : [defaultView.id]);
  const selectedView = viewIdToViewInfo[selectedViewIdArray[0]];

  const getDefaultFiltersForEntity = defaultFiltersForEntity(aggProjs);
  const getMergedDefaultFilters = () => ({
    ...(activeProjName ? getDefaultFiltersForEntity(activeProjName) : {}),
    ...defaultFilters,
  });

  const setNewView = ({ id, config }) => setFilters({ ...getMergedDefaultFilters(), ...config, viewId: [id] });

  const onChangeView = viewId => {
    setSelectedViewChanged(false);
    setControlledSelectedViewChanged?.(false);
    if (viewId) {
      if (viewIdToViewInfo[viewId]) {
        setNewView(viewIdToViewInfo[viewId]);
      }
    } else {
      setFilters({
        ...getMergedDefaultFilters(),
        viewId: [],
        ...(activeProjName ? { activeEntityId: [aggProjs[activeProjName].entityTypeId.name] } : {}),
      });
    }
  };

  const onSaveViewSuccess = view => {
    viewsRefetch().then(() => setNewView(view));
    enqueueSnackbar('View Saved Successfully', { variant: 'success' });
    setSelectedViewChanged(false);
    setControlledSelectedViewChanged?.(false);
  };

  const getDefaultViewByEntity = newProjName => {
    const customActiveView = userViews.map(v =>
      v.global && v.name === 'Active' ? replaceStateProperty(v, 'finding.state', `${aggProjs[newProjName!].pathAlias}.state`) : v
    );
    const userDefaultView = customActiveView.find(v => v.defaultView);
    const avalorDefaultView = customActiveView.find(v => v.global && v.name === 'Active') || [];
    return userDefaultView || avalorDefaultView;
  };

  const onSaveView = () => saveView({ ...viewIdToViewInfo[filters.viewId[0]], config: filters }, onSaveViewSuccess, true);

  const onDeleteView = () => viewsRefetch().then(() => setNewView(defaultView));

  useEffect(() => {
    const shouldSetToDefaultView = !filters.viewId && selectedViewIdArray?.[0] && Object.keys(viewIdToViewInfo).length && isDefaultFilter;
    if (shouldSetToDefaultView) {
      setNewView(viewIdToViewInfo[selectedViewIdArray[0]]);
    }
  }, [viewIdToViewInfo]);

  const SelectedView = hasAllowedPermissionToResource({ resource: PermissionEntitiesNames.VIEWS, permission: Permission.READ }) && (
    <Box ref={selectViewRef} sx={{ '> .MuiButtonBase-root:first-of-type': { ...flex.justifyBetween, width: 250 } }}>
      <SavedViewSelect
        selectedViewId={selectedViewIdArray[0]}
        onChange={onChangeView}
        options={userViews}
        userToDisplayName={usersInfo}
        viewsRefetch={viewsRefetch}
        onDeleteView={onDeleteView}
        openViewPopper={() => setOpenEditViewPopper(true)}
        defaultEnabled={defaultEnabled}
      />
      <SavedViewPopper
        view={selectedView || {}}
        anchorEl={selectViewRef.current}
        popperProps={{ open: openEditViewPopper, isEditMode: true }}
        handleClose={() => setOpenEditViewPopper(false)}
        refetchViews={viewsRefetch}
        screenType={viewScreenType}
      />
    </Box>
  );

  const SaveView = (controlledSelectedViewChanged || selectedViewChanged) &&
    !selectedView?.global &&
    selectedView?.createdByUserId === user.userId && (
      <Button
        variant="outlined"
        size="small"
        sx={{ backgroundColor: theme => theme.palette.colors.neutrals[100] }}
        onClick={filters?.viewId?.[0] ? onSaveView : undefined}>
        Save View
      </Button>
    );
  const SaveViewButtons = hasAllowedPermissionToResource({ resource: PermissionEntitiesNames.VIEWS, permission: Permission.CREATE }) && (
    <Box sx={{ ...flex.itemsCenter, px: 1, gap: 1 }} ref={saveAsViewButtonRef}>
      {(controlledSelectedViewChanged || selectedViewChanged) && (
        <Button size="small" variant="outlined" onClick={() => setOpenSaveViewPopper(true)}>
          Save As View
        </Button>
      )}
      <SavedViewPopper
        key="save-view-popper"
        view={{ config: filters, name: '', publicView: true }}
        anchorEl={saveAsViewButtonRef.current}
        popperProps={{ open: openSaveViewPopper, isEditMode: false }}
        handleClose={() => setOpenSaveViewPopper(false)}
        refetchViews={onSaveViewSuccess}
        screenType={viewScreenType}
      />
      {SaveView}
    </Box>
  );

  return {
    selectViewRef,
    saveAsViewButtonRef,
    selectedView,
    usersInfo,
    selectedViewIdArray,
    viewsRefetch,
    userViews: customActiveView,
    openEditViewPopper,
    openSaveViewPopper,
    defaultView,
    setOpenSaveViewPopper,
    setOpenEditViewPopper,
    onChangeView,
    onDeleteView,
    setNewView,
    onSaveViewSuccess,
    getDefaultViewByEntity,
    SaveViewButtons,
    SelectedView,
    SaveView,
    selectedViewChanged,
    setSelectedViewChanged,
    getDefaultFiltersForEntity,
  };
};

export const rawColumnStyle = {
  style: {
    backgroundColor: ({ palette }) => palette.colors.neutrals[150],
    '.MuiTableRow-hover:hover &': { backgroundColor: ({ palette }) => alpha(palette.colors.neutrals[300], 0.5) },
  },
  sx: { backgroundColor: ({ palette }) => palette.colors.neutrals[150], zIndex: 4 },
};

interface DeleteDialogProps {
  open: boolean;
  onClose: () => any;
  onSuccess?: () => void;
  totalCount: number;
  projId: EntityTypeID;
  filter: any;
}
export const DeleteEntitiesDialog: React.FC<DeleteDialogProps> = ({ open, onClose, totalCount, projId, filter, onSuccess }) => {
  const {
    api,
    accountData,
    featureFlags,
    accountEntities: { aggProjs, fieldTypeMap },
  } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();
  const { setRunInfo } = useContext(NotificationContext);
  const [inputValue, setInputValue] = useState('');

  const doClose = () => {
    setInputValue('');
    onClose();
  };

  const { mutate: apply } = useMutation({
    mutationFn: () => {
      const { headCells, viewId, investigationMode, orderBy, activeEntityId, tablePage, ...queryFilter } = filter;
      if (!totalCount) {
        enqueueSnackbar('No data to delete', { variant: 'error' });
        return Promise.reject(new Error('[validation error]: no data to delete'));
      }
      const cleanFilter = cleanEmptyFilters(filterToExpression(fieldTypeMap, queryFilter, featureFlags[FeatureFlags.NewDynamicRange]));
      if (!cleanFilter) {
        enqueueSnackbar('Filter is required', { variant: 'error' });
        return Promise.reject(new Error('[validation error]: filter is required'));
      }

      doClose();
      return api(DELETE, { options: { projId, filter: cleanFilter } }).then(({ data }) =>
        setRunInfo({
          runId: data.deleteByFilter.workflowRun.runId,
          wfId: data.deleteByFilter.workflowRun.id, // TODO change id to wfId and remove .workflowRun
          returnStatusOnly: true,
          workflowType: WorkflowType.DataDeletion,
          onSuccess,
        })
      );
    },
  });

  const isDeleteDisabled = inputValue !== accountData.name;

  return (
    <AvDialog
      open={open}
      onClose={doClose}
      titleVariant="error"
      noPadding
      title="Confirm Deletion"
      footer={
        <Box sx={{ ...flex.justifyEndCenter, gap: 2 }}>
          <Button onClick={doClose}>Cancel</Button>
          <Button onClick={() => apply()} variant="contained" color="error" disabled={isDeleteDisabled}>
            <Delete />
            Delete
          </Button>
        </Box>
      }>
      <Box sx={{ px: 3, ...flex.col, gap: 2, maxWidth: 620 }}>
        <div>
          You are about to permanently delete{' '}
          <span style={{ fontWeight: 600 }}>
            {totalCount} {aggProjs[projId.name].projDisplayName}
          </span>{' '}
          records that match the current filter.
          <div>Please note that as part of the deletion process, relevant dependent records will be recalculated.</div>
        </div>
        <Alert severity="error" icon={false}>
          All data will be permanently deleted from the system and cannot be restored
        </Alert>
        <Box sx={{ ...flex.col, pt: 1 }}>
          <Divider flexItem />
        </Box>
        <Box sx={{ userSelect: 'none' }}>{`To confirm, type “${accountData.name}” in the box below`}</Box>
        <Box sx={{ pb: 1 }}>
          <TextInput
            sx={{ width: '100%', maxWidth: 'unset' }}
            isRequired
            onChange={setInputValue}
            value={inputValue}
            placeholder="Type the text above"
          />
        </Box>
      </Box>
    </AvDialog>
  );
};

const DELETE = gql`
  mutation ($projId: ProjectionIDInput!, $filter: filterScalar!) {
    deleteByFilter(projId: $projId, filter: $filter) {
      workflowRun {
        id
        runId
      }
    }
  }
`;
