import React, { useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { AppBar, Box, Button, buttonBaseClasses, Divider, Portal, SxProps, Toolbar, useTheme } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { Link, useNavigate } from 'react-router-dom';
import FullLogo from '../../assets/avalor_logo_purple.png';
import { AccountType } from '../../context/AvContext.types';
import { AppDto, useAvContext } from '../../context/AvContextProvider';
import { useAllApps } from '../../hooks/useAllApps';
import useKnowledgeBase from '../../hooks/useKnowledgeBase';
import useZendesk from '../../hooks/useZendesk';
import { APP_PATHS, AppPathsValue, PAGE_PATHS, ROLES } from '../../types';
import { App, SubMenuItem } from '../../types/sideBar.types';
import { iconSize, uniqBy } from '../../utils/Utils';
import Zendesk, { ZendeskAPI } from '../../utils/ZendeskConfig';
import { flex } from '../AvThemeProvider';
import AvTooltip from '../AvTooltip';
import Select from '../Select';
import { APPBAR_HEIGHT } from './appbar.constants';
import { appBarItemHoveredStyle, appBarItemSelectedStyle, AppItem } from './AppItem';
import { useEnabledAppsOptions } from './hooks';
import { appsSideBar } from './sidebar.constants';
import SpotlightSearch from './SpotlightSearch';
import UserMenu from './UserMenu';
import { ReactComponent as Apps } from '../../assets/Apps.svg';
import { ReactComponent as Explore } from '../../assets/apps/Explorer.svg';
import { ReactComponent as QuestionFull } from '../../assets/apps/question full.svg';
import { ReactComponent as Comment } from '../../assets/CommentFull.svg';
import { ReactComponent as Settings } from '../../assets/screens/Settings_full.svg';

const useSetFav = () => {
  const { user, refetchAdditionalData, api } = useAvContext();
  return useMutation({
    mutationFn: ({ isFavorite, app }: { isFavorite: boolean; app: AppDto }) =>
      api(isFavorite ? ADD_TO_FAVORITE : REMOVE_FROM_FAVORITE, {
        options: {
          userId: user.userId,
          app,
        },
      }).then(refetchAdditionalData),
  });
};

interface SystemButtonProps {
  onClick: () => void;
  appBarItemStyle: SxProps;
  icon: React.JSX.Element;
  title?: string;
}
const AppBarSystemButton: React.FC<SystemButtonProps> = ({ onClick, appBarItemStyle, icon, title = '' }) => (
  <AvTooltip title={title}>
    <Button sx={appBarItemStyle} onClick={onClick}>
      {icon}
    </Button>
  </AvTooltip>
);

const AvAppBar = () => {
  const theme: any = useTheme();
  const {
    user,
    accountId,
    accountData,
    selectedApp,
    getPathName,
    api,
    userPermissions: { hasAllowedPermission },
  } = useAvContext();
  const { openZendeskWidget, isOpen, closeZendeskWidget } = useZendesk();
  const getKBtoken = useKnowledgeBase({ newPage: true, api });
  const { data: AllApps = [] } = useAllApps();
  const navigate = useNavigate();
  const { mutate } = useSetFav();
  const favAppsSystemName = (user?.favoriteApps || []).map(v => v.toLowerCase());
  const filteredAppsOption = useEnabledAppsOptions();

  const [filteredAppsBarItems, setFilteredAppsBarItems] = useState(
    filteredAppsOption.filter(app => favAppsSystemName.includes(app.id) || app.id === selectedApp)
  );

  const firstEnabledPathPerGlobalApp: Partial<Record<AppPathsValue, SubMenuItem | undefined>> = [
    APP_PATHS.PLATFORM,
    APP_PATHS.EXPLORE,
  ].reduce(
    (acc, app) => ({
      ...acc,
      [app]: appsSideBar[app].flatMap(s => s.subMenu).find(({ path }) => hasAllowedPermission({ path, app })),
    }),
    {}
  );

  useEffect(() => {
    setFilteredAppsBarItems(
      uniqBy(
        [...filteredAppsBarItems, ...filteredAppsOption.filter(app => favAppsSystemName.includes(app.id) || app.id === selectedApp)],
        app => app.id
      )
    );
  }, [accountData.apps, selectedApp, user.favoriteApps]);

  const toggleFavoriteApp = (appClientId, isFavorite, e) => {
    e.stopPropagation();
    const app = AllApps.find(appDto => appDto.name.toLowerCase() === appClientId);
    if (app) {
      mutate({ isFavorite, app });
    }
  };

  const OnClickAppBarItem = (app: App, e?: any) => {
    const urlPath = window.location.pathname;
    const firstAvailablePath = appsSideBar[app.id]
      .flatMap(menuItem => menuItem.subMenu.map(menu => (menu.subMenu ? undefined : menu.path)))
      .filter(v => v)
      .find(path => hasAllowedPermission({ path: path!, app: app.id }));
    if (app && (e?.metaKey || e?.ctrlKey)) {
      window.open(`${window.location.origin}${getPathName(firstAvailablePath, '', app.id)}`, '_blank');
    } else if (app && urlPath !== getPathName(PAGE_PATHS.TICKETS, '', app.id)) {
      navigate(getPathName(firstAvailablePath, '', app.id));
    }
  };

  return (
    <AppBar
      sx={{
        height: APPBAR_HEIGHT,
        zIndex: theme.zIndex.modal,
        backgroundColor: theme.palette.colors.neutrals[100],
        boxShadow: '0px 4px 12px 0px rgba(18, 18, 27, 0.08)',
      }}>
      <Portal container={document.body}>
        <SpotlightSearch apps={filteredAppsOption} />
      </Portal>
      <Toolbar
        sx={{
          height: APPBAR_HEIGHT,
          [theme.breakpoints.up('sm')]: {
            minHeight: APPBAR_HEIGHT,
          },
        }}>
        <Box sx={{ mr: 4 }}>
          <Link to={accountId}>
            <img alt="back to homepage logo" src={FullLogo} style={{ height: 28, width: 127 }} />
          </Link>
        </Box>
        <Select
          label={<Apps />}
          showInput={false}
          onChange={appId => OnClickAppBarItem(filteredAppsOption.find(({ id }) => id === appId)!)}
          renderOption={app => (
            <AppItem
              {...app}
              appIconSize={24}
              disableHover
              showDescription
              toggleFavoriteApp={e => toggleFavoriteApp(app.id, !favAppsSystemName.includes(app.id), e)}
              isFavorite={favAppsSystemName.includes(app.id)}
            />
          )}
          style={{
            [`&.${buttonBaseClasses.root}`]: {
              p: 1,
              borderRadius: '12px',
              svg: {
                color: theme.palette.colors.neutrals[500],
                ...iconSize(22),
              },
              ...appBarItemHoveredStyle(theme),
              '&.Mui-focusVisible': appBarItemSelectedStyle(theme),
            },
          }}
          variant="text"
          transparent
          getValueFunc={({ id }) => id}
          getLabelFunc={({ name }) => name}
          showSelection={false}
          options={filteredAppsOption}
          size="small"
          value={selectedApp}
        />
        <Divider flexItem orientation="vertical" sx={{ height: 24, alignSelf: 'center', pr: 1 }} />
        <Box sx={{ ...flex.itemsCenter, gap: '10px', pl: 2 }}>
          {filteredAppsBarItems.map(app => (
            <AppItem {...app} isSelected={app.id === selectedApp} onClick={e => OnClickAppBarItem(app, e)} key={app.name} />
          ))}
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ ...flex.itemsCenter, gap: '10px', pl: 1.25 }}>
          <Button
            sx={{ ...appBarItemStyle(theme, selectedApp === APP_PATHS.EXPLORE), pr: 1.5 }}
            onClick={() => {
              navigate(getPathName(firstEnabledPathPerGlobalApp[APP_PATHS.EXPLORE]!.path, '', APP_PATHS.EXPLORE));
            }}>
            <Explore />
            Explore
          </Button>
        </Box>
        <Divider flexItem orientation="vertical" sx={{ height: 24, alignSelf: 'center', margin: '16px' }} />
        <Box sx={{ ...flex.itemsCenter, gap: 2 }}>
          <Box sx={{ ...flex.itemsCenter, gap: '5px' }}>
            {
              // TODO: permissions
              user.role !== ROLES.PRE_POV && (
                <>
                  <AppBarSystemButton
                    title="Knowledge Center"
                    onClick={getKBtoken}
                    appBarItemStyle={appBarItemStyle(theme)}
                    icon={<QuestionFull />}
                  />
                  {accountData.accountType !== AccountType.POC && (
                    <Box>
                      <Zendesk
                        offset={{ horizontal: '210px', vertical: '40px' }}
                        onLoaded={() => ZendeskAPI('webWidget', 'hide')}
                        position={{ horizontal: 'right', vertical: 'top' }}
                      />
                      <AppBarSystemButton
                        title="Contact Support"
                        onClick={isOpen ? closeZendeskWidget : openZendeskWidget}
                        icon={<Comment />}
                        appBarItemStyle={appBarItemStyle(theme, isOpen)}
                      />
                    </Box>
                  )}
                </>
              )
            }
            {firstEnabledPathPerGlobalApp[APP_PATHS.PLATFORM] && (
              <AppBarSystemButton
                title="Configure"
                onClick={() => {
                  navigate(getPathName(firstEnabledPathPerGlobalApp[APP_PATHS.PLATFORM]!.path, '', APP_PATHS.PLATFORM));
                }}
                appBarItemStyle={appBarItemStyle(theme, selectedApp === APP_PATHS.PLATFORM)}
                icon={<Settings />}
              />
            )}
          </Box>
          <Divider flexItem orientation="vertical" sx={{ height: 24, alignSelf: 'center' }} />
          <UserMenu setFilteredAppsBarItems={setFilteredAppsBarItems} />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default AvAppBar;

const appBarItemStyle = (theme, isSelected?: boolean) => ({
  p: 1,
  borderRadius: '12px',
  svg: {
    transition: theme.transitions.create(['color'], {
      duration: theme.transitions.duration.shortest,
    }),
    color: theme.palette.colors.neutrals[500],
    ...iconSize(22),
  },
  ...appBarItemHoveredStyle(theme),
  ...(isSelected && appBarItemSelectedStyle(theme)),
});

const ADD_TO_FAVORITE = gql`
  mutation ($userId: String!, $app: AppDtoInput!) {
    addFavoriteApp(userId: $userId, app: $app)
  }
`;

const REMOVE_FROM_FAVORITE = gql`
  mutation ($userId: String!, $app: AppDtoInput!) {
    removeFavoriteApp(userId: $userId, app: $app)
  }
`;
