import { gql } from '@apollo/client';
import { useQuery } from '@tanstack/react-query';
import { useAvContext } from '../../context/AvContextProvider';
import { AccountEntities } from '../../context/context.type';
import { FeatureFlags } from '../../types';

const SETTING_FOR_FIELD_V2 = gql`
  query ($fieldName: String) {
    getSettingsInfoByFieldName(fieldName: $fieldName) {
      settingsType
      synced
    }
  }
`;

const SETTING_FOR_TYPE_V2 = gql`
  query ($settingsType: String) {
    getSettingsForTypeV2(settingsType: $settingsType) {
      spfFields
      spfFieldsSynced
    }
  }
`;

const SETTING_INFO_BY_TYPE = gql`
  query ($settingsType: String) {
    getSettingsInfoBySettingsType(settingsType: $settingsType) {
      settingsType
      synced
    }
  }
`;

export const useIsFieldSyncedToSetting = fieldName => {
  const { api } = useAvContext();
  return useQuery({
    queryKey: [fieldName, 'field-sync-to-setting'],
    queryFn: () => api(SETTING_FOR_FIELD_V2, { options: { fieldName } }),
    gcTime: 0,
    enabled: !!fieldName,
    select: ({ data }) => data.getSettingsInfoByFieldName,
  });
};

export const useIsSettingSyncedToField = (settingsType: SettingType) => {
  const { api, featureFlags } = useAvContext();
  const isGroupingV2 = featureFlags[FeatureFlags.GroupingRulesV2] && settingsType === SettingType.GroupingRules;
  const queryFnV2 = () =>
    api(SETTING_INFO_BY_TYPE, { options: { settingsType } }).then(({ data }) => ({
      synced: data.getSettingsInfoBySettingsType.synced,
      settingsType: data.getSettingsInfoBySettingsType.settingsType,
    }));
  const queryFn = () =>
    api(SETTING_FOR_TYPE_V2, { options: { settingsType } }).then(({ data }) => ({
      synced: data.getSettingsForTypeV2.spfFieldsSynced,
    }));
  return useQuery({
    queryKey: [settingsType, 'settings-sync-to-field'],
    queryFn: isGroupingV2 ? queryFnV2 : queryFn,
    gcTime: 0,
  });
};

const GET_LIST = gql`
  query {
    aggProjections {
      meta {
        id {
          name
          builtIn
        }
      }
      settings
    }
  }
`;

export const useProjectionSettings = () => {
  const { api } = useAvContext();
  return useQuery({
    queryKey: ['aggProjections'],
    queryFn: () =>
      api(GET_LIST).then(
        ({ data }) =>
          data.aggProjections?.reduce(
            (acc, curr) => ({
              ...acc,
              [curr.meta.id.name]: curr,
            }),
            {}
          )
      ),
  });
};

export const useSeveritySettings = () => {
  const { data } = useProjectionSettings();
  return data?.uber_findings.settings.severities?.filter(s => s.label !== 'NONE')?.reverse() || [];
};

export enum SettingType {
  GroupingRules = 'GROUPING_RULES',
  SeveritySLA = 'SEVERITY_AND_SLA',
  Score = 'SCORING',
  GroupingRulesAlerts = 'GROUPING_RULES_ALERTS',
  SeveritySLAAlerts = 'SEVERITY_AND_SLA_ALERTS',
  ScoreAlerts = 'SCORING_ALERTS',
  UnificationRules = 'UNIFICATION_RULES',
}

export const SettingsName = {
  [SettingType.Score]: 'Score Settings',
  [SettingType.SeveritySLA]: 'Severity & SLA',
  [SettingType.GroupingRules]: 'Grouping Rules',
  [SettingType.UnificationRules]: 'Data Unification',
  [SettingType.ScoreAlerts]: 'Score Settings',
  [SettingType.SeveritySLAAlerts]: 'Severity & SLA',
  [SettingType.GroupingRulesAlerts]: 'Grouping Rules',
};

export const getRuleFilterOptions = (aggProjs: AccountEntities, projectionName: string, featureFlags) =>
  aggProjs[aggProjs[projectionName].aggregates.name].fieldList[featureFlags[FeatureFlags.GroupingRulesV2] ? 'EVAL' : 'SPF'].filter(
    f => !f.visibilityConfig.config.hidden && !f.id.endsWith('grouping_key')
  );
