import React, { useMemo } from 'react';
import { Box, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { YAxis } from 'recharts';
import { severityOptions } from '../../utils/severity.utils.tsx';
import { abbreviateNumber, dotStyle, isArrayofArrays } from '../../utils/Utils';
import AvLegend from '../AvLegend.tsx';
import { flex } from '../AvThemeProvider.tsx';
import Select from '../Select';
import AvComposedWidget from './AvComposedWidget';
import StackedBarTooltip from './StackedBarTooltip.tsx';

const getNormalizedData = (data, severities) =>
  data
    .reduce((acc, item) => {
      const itemIndex = acc.findIndex(({ date }) => date === item.date);
      if (itemIndex !== -1) {
        acc[itemIndex] = {
          ...acc[itemIndex],
          ...item,
        };
      } else {
        return [...acc, item];
      }
      return acc;
    }, [])
    .map(item =>
      Object.keys(item).reduce((acc, key) => {
        if (severities.includes(key)) {
          acc[`Normalized.${key}`] = (item[key] / item.totalFindings) * item.risk;
        }
        acc[key] = item[key];
        return acc;
      }, {})
    )
    .map(item => {
      const normalizedValues = Object.keys(item).filter(key => key.startsWith('Normalized'));
      const minSeveritySize = 0.5;
      const smallNormalizedValues = normalizedValues.filter(key => item[key] < minSeveritySize);
      const largeNormalizedValuesCount = normalizedValues.length - smallNormalizedValues.length;
      const diffNormalizedValues =
        smallNormalizedValues.reduce((acc, key) => acc + (minSeveritySize - item[key]), 0) / largeNormalizedValuesCount;
      return Object.keys(item).reduce((acc, key) => {
        if (normalizedValues.includes(key)) {
          acc[key] = item[key] < minSeveritySize ? minSeveritySize : Math.max(item[key] - diffNormalizedValues, 1);
        } else {
          acc[key] = item[key];
        }
        return acc;
      }, {});
    });

function StackedBarSeverity({ data: d, series: s, options = [], selectedAssetId, setSelectedAsset, hiddenLegends }) {
  const selectedAsset = useMemo(() => options.find(({ id }) => id === selectedAssetId), [options, selectedAssetId]);
  const theme = useTheme();
  const severities = ['CRITICAL', 'HIGH', 'MEDIUM', 'LOW'];
  const data = useMemo(() => (isArrayofArrays(d) ? getNormalizedData([...d[0], ...d[1]], severities) : []), [d]);
  const series = useMemo(() => s.sort((a, b) => (a.type === 'line' || b.type === 'line' ? -1 : 1)), [s]);
  const legendSeries = useMemo(() => s.filter(({ name }) => !hiddenLegends.includes(name)), [s]);
  const rightYaxis = (
    <YAxis
      key="right"
      label={{
        value: 'Assets',
        style: { textAnchor: 'middle', fill: theme.palette.colors.neutrals[600] },
        angle: -90,
        position: 'left',
        dx: 50,
        offset: 0,
      }}
      padding={{ right: 20 }}
      yAxisId="right"
      tickCount={5}
      tickMargin={5}
      orientation="right"
      axisLine={false}
      tickLine={false}
      tickFormatter={abbreviateNumber}
      allowDecimals={false}
    />
  );

  const selectStyle = {
    p: 0,
    px: 1,
    maxWidth: '214px',
    border: theme => `1px solid ${theme.palette.colors.neutrals[400]}`,
    '&.active-filter': { backgroundColor: theme.palette.colors.neutrals[100] },
    backgroundColor: theme => theme.palette.colors.neutrals[100],
  };

  const renderOptionLabel = data => (
    <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
      <Box sx={{ ...dotStyle(data?.color), flexShrink: 0 }} />
      <span>{data?.title}</span>
    </Box>
  );

  const getTooltipContent = ({ payload } = {}) => (
    <StackedBarTooltip
      payload={payload.map(item => ({ ...item, payload: { ...item.payload, name: item.payload.title } }))}
      hiddenMetrics={severityOptions.map(severity => ({ title: `Normalized.${severity.value}` })).map(s => s.title)}
    />
  );

  return (
    <Box sx={{ ...flex.col, gap: '16px', height: '100%', width: '100%', overflow: 'hidden' }}>
      <Box sx={{ ...flex.justifyStartCenter, gap: 1.5, ml: 1 }}>
        <Select
          label={renderOptionLabel(selectedAsset)}
          renderOption={renderOptionLabel}
          value={selectedAsset?.title}
          onChange={setSelectedAsset}
          getLabelFunc={({ title }) => title}
          getValueFunc={item => item}
          options={options.map(item => ({ ...item, value: item.id }))}
          variant="filter"
          showInput={false}
          isRequired
          size="small"
          style={selectStyle}
        />
        <Box sx={{ fontSize: '16px', fontWeight: 600, color: theme => theme.palette.colors.neutrals[500] }}>Risk Severity Overtime</Box>
      </Box>
      <Box sx={{ ml: 1, px: 1 }}>
        <AvLegend series={legendSeries} isHorizontal />
      </Box>
      <AvComposedWidget
        chartProps={{ margin: { top: 20, right: 20, bottom: 30, left: 20 } }}
        xAxisKey="date"
        showLegend={false}
        isDate
        series={series}
        data={data}
        additionalAxis={rightYaxis}
        defaultAxisProps={{ y: { tickCount: 6, domain: [0, 10] } }}
        getTooltipContent={getTooltipContent}
        labels={{ y: 'Risk Score' }}
      />
    </Box>
  );
}

StackedBarSeverity.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  series: PropTypes.arrayOf(PropTypes.shape()),
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedAssetId: PropTypes.string,
  setSelectedAsset: PropTypes.func,
  hiddenLegends: PropTypes.arrayOf(PropTypes.string),
};

StackedBarSeverity.defaultProps = {
  data: [],
  series: [],
  selectedAssetId: undefined,
  setSelectedAsset: () => {},
  hiddenLegends: [],
};

export default StackedBarSeverity;
