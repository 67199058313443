import { useMemo } from 'react';
import { MutationOptions, QueryObserverOptions, useMutation, useQueries, useQuery } from '@tanstack/react-query';
import { QueriesOptions } from '@tanstack/react-query/build/legacy/useQueries';
import { useAvContext } from '../context/AvContextProvider';
import { isTokenExpired, useUserActivityStatus } from '../hooks/auth';

export const useAvQuery = <TData = unknown, TError = unknown>(options: QueryObserverOptions<TData, TError>) => {
  const isEnabled = useReturnQueryData(options);
  return useQuery<TData, TError>({ ...options, enabled: isEnabled });
};

export const useAvQueries = (options: QueriesOptions<any>) => {
  const isEnabled = useReturnQueryData(options);
  return useQueries({ ...options, enabled: isEnabled });
};

export const useAvMutation = (options: MutationOptions) => useMutation(options);

const useReturnQueryData = (options: any) => {
  const { accountId, accessToken } = useAvContext();
  const userIsActive = useUserActivityStatus();
  return useMemo(
    () => userIsActive && !isTokenExpired(accountId) && (options.enabled ?? true),
    [userIsActive, options.enabled, accessToken, accountId]
  );
};
