import React, { CSSProperties, useMemo, useState } from 'react';
import { alpha, Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { keepPreviousData } from '@tanstack/react-query';
import { startOfDay } from 'date-fns';
import RGL, { WidthProvider } from 'react-grid-layout';
import { useLocation } from 'react-router-dom';
import AvFilters from '../../components/AvFilters';
import { flex, PIE_PALETTES } from '../../components/AvThemeProvider';
import ExportButton from '../../components/ExportButton';
import Select from '../../components/Select';
import ActiveAssetsHistogram from '../../components/Widgets/ActiveAssetsHistogram';
import { WidgetTitle } from '../../components/Widgets/layout.components';
import Status from '../../components/Widgets/Status';
import Pie from '../../components/Widgets/VisualizationWidgets/AvPie';
import Widget from '../../components/Widgets/Widget';
import { useAvContext } from '../../context/AvContextProvider';
import { SearchContextKeys } from '../../context/SearchContext.types';
import { useCustomSearchParams } from '../../hooks/UseCustomSearchParams';
import useQuerySql from '../../hooks/useQuerySql';
import { FeatureFlags } from '../../types';
import { ScreenType } from '../../types/savedViews.types';
import { useWidgetData } from '../../utils/dashboardDataUtils';
import { getSeverityByScore } from '../../utils/severity.utils';
import { abbreviateNumber, getIfValidStringifiedObject } from '../../utils/Utils';
import { useExportDashboard } from '../CustomDashboards/hooks';
import { DashboardType, PieSubType } from '../CustomDashboards/types';
import { useSavedViews } from '../Entities/hooks';
import { FileFormat } from '../Reports/types';
import { useSeveritySettings } from '../Settings/hooks';
import { useBuildWhereClause } from '../Tickets/hooks';
import RiskAndDrilldownWidget from './RiskAndDrilldownWidget';
import TrendWidget from './TrendWidget';
import { TSelectedAsset } from './types';
import {
  DAYS_AGO_PERIOD,
  defaultFilters,
  GRID_LAYOUT,
  methodOptions,
  MONTH_AGO_PERIOD,
  NUM_OF_PIE_ITEMS,
  startAdornment,
  statusesData,
  timeComparisonXMonth,
  useRiskMeasurementOptions,
} from './Utils';
import { ReactComponent as StartingPoint } from '../../assets/StartingPoint.svg';

const ReactGridLayout = WidthProvider(RGL);

const DashboardRiskPage: React.FC = () => {
  const theme = useTheme();
  const {
    featureFlags,
    accountEntities: { aggProjs },
  } = useAvContext();
  const location = useLocation();
  const showExportDashboard = featureFlags[FeatureFlags.AllowExportDashboard];
  const { pathAlias } = aggProjs.uber_findings;
  const widgetData = useWidgetData();
  const settingsSeverities = useSeveritySettings();
  const [filters, setFilters] = useCustomSearchParams({
    defaultFilter: defaultFilters(pathAlias),
    shouldBeArray: v => !['method', 'scatterBy'].includes(v),
    contextSearchKey: SearchContextKeys.RiskDashboard,
  });
  const { exportDashboard, isLoading: isExportingDashboard } = useExportDashboard({
    dashboardExportDto: {
      dashboardType: DashboardType.RISK_DASHBOARD,
      fileFormat: FileFormat.PDF_FORMAT,
      searchParams: location.search,
    },
  });

  const { method, scatterBy } = filters;
  const assetsOptions = aggProjs.uber_findings.fieldList.INTERACTIVE;
  const [selectedAsset, setSelectedAsset] = useState<TSelectedAsset | null>(null);

  const [slicer, setSlicer] = useState({});
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1850));
  const isXsmallScreen = useMediaQuery(theme.breakpoints.down(1310));
  const screenSize = useMemo(
    () => (isXsmallScreen ? 'isXsmallScreen' : isSmallScreen ? 'isSmallScreen' : 'defaultScreen'),
    [isSmallScreen, isXsmallScreen]
  );
  const { measurementOptions, selectValueMap } = useRiskMeasurementOptions({ method: method === 'risk_max' ? 'MAX' : 'AVG' });

  const riskCell = (v, color?, bgColor?) => (
    <Box sx={{ fontWeight: 600, textAlign: 'end' }}>
      <Box
        component="span"
        sx={{
          py: '3px',
          px: '6px',
          borderRadius: 2,
          ...(color && bgColor
            ? {
                color,
                background: bgColor,
              }
            : {}),
        }}>
        {abbreviateNumber(v)}
      </Box>
    </Box>
  );

  const extraPieHeadCells = [
    {
      id: 'risk',
      label: 'Risk',
      type: 'number',
      disableSortBy: true,
      formatter: val => {
        const severityProps = getSeverityByScore(theme, settingsSeverities, val);
        return !severityProps
          ? riskCell(val)
          : riskCell(val, severityProps.color, alpha(severityProps.bgColor, severityProps.severity === 'MEDIUM' ? 0.2 : 0.15));
      },
    },
  ];

  const now = useMemo(() => new Date().toISOString(), []);

  const getDaysAgoDate = days => {
    const endDate = new Date();
    endDate.setDate(endDate.getDate() - days);
    return startOfDay(endDate).toISOString();
  };

  const getMonthAgoDate = months => {
    const endDate = new Date();
    endDate.setMonth(endDate.getMonth() - months);
    return startOfDay(endDate).toISOString();
  };

  const isShowSlicerBadge = useMemo(() => Object.keys(slicer).some(item => slicer[item]?.value?.length > 0), [slicer]);
  const getSlicerBadgeProps = (type = '') => {
    if (!isShowSlicerBadge) {
      return null;
    }
    const field = Object.keys(slicer).find(item => slicer[item]?.value?.length > 0 && type === item);
    const slicerData = (field && slicer[field]?.value) || [];
    return { isSlicer: !!slicerData.length, data: slicerData };
  };
  const isFilterField = key => !['keyMetrics', 'method', 'scatterBy'].includes(key);

  const transformedFilters = useMemo(
    () =>
      Object.keys(filters).reduce(
        (acc, key) => {
          if (isFilterField(key)) {
            acc.filters = { ...acc.filters, [key]: filters[key] };
            return acc;
          }
          const value = getIfValidStringifiedObject(filters[key]) || filters[key];
          acc.props = { ...acc.props, [key]: value };
          return acc;
        },
        {
          filters: {},
          props: {
            keyMetrics: ['active_findings_granular'],
          },
        }
      ),
    [filters]
  );

  const riskMetricQueryGranular = method => `${method === 'risk_avg' ? 'avg_risk_granular' : 'max_risk_granular'} as risk`;

  const { SelectedView, SaveViewButtons, setSelectedViewChanged } = useSavedViews({
    viewScreenType: ScreenType.RiskDashboard,
    filters,
    setFilters,
    defaultFilters: defaultFilters(pathAlias),
  });

  const onFilterChange = (field, value) => {
    setFilters({ ...filters, [field]: value || '' });
    setSlicer({});
    setSelectedViewChanged(true);
  };

  const onClearFiltersHandler = () => {
    const newFilters = {
      ...Object.keys(filters).reduce((acc, key) => {
        if (!isFilterField(key)) {
          acc[key] = filters[key];
        }
        return acc;
      }, {}),
    };
    setFilters(newFilters);
  };

  const onClearMetricsHandler = () => {
    const newFilters = {
      ...Object.keys(filters).reduce((acc, key) => {
        if (!isFilterField(key)) {
          return acc;
        }
        acc[key] = filters[key];
        return acc;
      }, {}),
    };
    setFilters(newFilters);
  };

  const slicersFilters = useMemo(
    () =>
      Object.keys(slicer).reduce((acc, key) => {
        const value = slicer[key].value.length ? slicer[key].value.map(item => item.value) : undefined;
        if (value !== undefined) {
          acc[key] = value.map(item => (item === 'Empty' ? null : item));
        }
        return acc;
      }, {}),
    [slicer]
  );

  const maxAllCounts = (counts, baseObj) =>
    Object.keys(counts).reduce((obj, key) => {
      if (key === 'risk') {
        return { ...obj, [key]: obj[key] < counts[key] ? counts[key] : obj[key] };
      }
      if (key === 'count') {
        return { ...obj, [key]: (obj[key] || 0) + counts[key] };
      }
      return { ...obj };
    }, baseObj);

  const avgAllCounts = (counts, baseObj, data) => {
    const transData = Object.keys(counts).reduce((obj, key) => {
      if (key === 'risk') {
        return { ...obj, [key]: (obj[key] || 0) + counts[key], riskCount: (obj.riskCount || 1) + 1 };
      }
      if (key === 'count') {
        return { ...obj, [key]: (obj[key] || 0) + counts[key] };
      }
      return { ...obj };
    }, baseObj);
    return transData.riskCount === data.length - NUM_OF_PIE_ITEMS
      ? { ...transData, risk: transData.risk / transData.riskCount }
      : transData;
  };

  const othersHandler = (counts, baseObj, data = []) => ({
    ...(method === 'risk_max' ? maxAllCounts(counts, baseObj) : avgAllCounts(counts, baseObj, data)),
    disable: true,
  });
  const filtersWhere = useBuildWhereClause({
    filters: { ...transformedFilters.filters, ...slicersFilters },
  });

  const filtersWhereForSlicers = useBuildWhereClause({
    filters: { ...transformedFilters.filters },
  });
  const filtersWhereForDrilldown = useBuildWhereClause({
    filters: {
      ...transformedFilters.filters,
      ...(selectedAsset?.title && {
        [scatterBy]: [selectedAsset.title === 'Empty' ? null : selectedAsset.title],
      }),
    },
  });
  const filtersWhereForHistogram = useBuildWhereClause({
    filters: { ...transformedFilters.filters, ...slicersFilters },
    extra: ["finding.state = 'ACTIVE'"],
  });

  const { data: scatterList } = useQuerySql({
    key: 'scatterList',
    sql: widgetData.scatterAssetList.sql(scatterBy, filtersWhere, riskMetricQueryGranular(method)),
    onSuccess: d => {
      const transData = widgetData.scatterAssetList.transformData(d);
      setSelectedAsset(transData[0]);
      return transData;
    },
    options: { placeholderData: keepPreviousData, gcTime: 0 },
  });

  const renderExportButton = showExportDashboard && ( // TODO
    <ExportButton
      customTooltipText={isExportingDashboard ? 'Exporting dashboard...' : 'Export dashboard as PDF'}
      disabled={isExportingDashboard}
      loading={isExportingDashboard}
      onClick={exportDashboard}
      showToolbarOnDisabled
    />
  );

  return (
    <Box sx={{ ...flex.col, width: '100%', gap: 2, overflowY: 'auto' }}>
      <Typography variant="h3" sx={{ mb: 1 }}>
        Risk
      </Typography>
      <Box sx={{ ...flex.itemsCenter, gap: 2 }}>
        {SelectedView}
        <Box sx={{ flexGrow: 1 }} />
        {SaveViewButtons}
      </Box>
      <Box sx={{ ...flex.justifyBetweenCenter }}>
        <AvFilters
          setFilters={setFilters}
          updateFilters={onFilterChange}
          activeProjName="uber_findings"
          filters={transformedFilters.filters}
          onClearFilters={onClearFiltersHandler}
          fields={aggProjs.uber_findings.fieldList.INTERACTIVE}
        />
        <Box sx={{ ...flex.itemsCenter, gap: 1.5, color: theme.palette.colors.neutrals[500] }}>
          {renderExportButton}
          <Box sx={{ whiteSpace: 'nowrap' }}>Risk Scoring Method</Box>
          <Select
            variant="filter"
            showInput={false}
            hideValue
            label={startAdornment(methodOptions.find(m => m.value === method)!.title)}
            isRequired
            size="small"
            style={{
              maxWidth: '214px',
              border: `1px solid ${theme.palette.colors.neutrals[400]}`,
              backgroundColor: theme.palette.colors.neutrals[100],
              '&.active-filter': { backgroundColor: theme.palette.colors.neutrals[100] },
            }}
            getValueFunc={({ value }) => value}
            value={method}
            onChange={v => onFilterChange('method', v)}
            options={methodOptions}
          />
        </Box>
      </Box>
      <Box sx={{ overflow: 'auto', position: 'relative' }}>
        <ReactGridLayout
          useCSSTransforms={false}
          className="layout"
          margin={[12, 12]}
          layout={GRID_LAYOUT[screenSize]}
          cols={12}
          rowHeight={35}>
          <Box key="a" sx={{ ...flex.col, backgroundColor: theme.palette.white.main, position: 'relative' }}>
            <Widget
              height="100%"
              historicalDataProps={{ hideText: true }}
              sliceProps={{ ...getSlicerBadgeProps(), hideText: true }}
              title={widgetData.riskStatus.title}
              transformData={widgetData.riskStatus.transformData}
              sql={widgetData.riskStatus.sqlNewTimeBuckets(
                getMonthAgoDate(timeComparisonXMonth),
                now,
                filtersWhere,
                riskMetricQueryGranular(method)
              )}
              queryOptions={{ placeholderData: keepPreviousData }}>
              <Status stickDescription sx={{ py: 0, px: 0 }} series={statusesData.riskScore.series} />
            </Widget>
          </Box>
          <div key="b" style={{ ...flex.col, backgroundColor: theme.palette.white.main, position: 'relative' } as CSSProperties}>
            <Widget
              height="100%"
              sliceProps={getSlicerBadgeProps()}
              title={widgetData.riskTrendOverTime.title}
              historicalDataProps={{ hideText: false }}
              transformData={widgetData.riskTrendOverTime.transformData}
              sql={widgetData.riskTrendOverTime.sql(
                transformedFilters.props?.keyMetrics?.map(m => selectValueMap[m]) || [],
                getMonthAgoDate(MONTH_AGO_PERIOD - 1),
                now,
                filtersWhere
              )}
              noDataProps={{
                title: 'This is a starting off point!',
                description: 'as data flows in, we’ll show you the trends of how you’re risk score changes',
                icon: <StartingPoint style={{ width: '100%', maxWidth: 250 }} />,
              }}
              errorProps={{ text: 'Try To Clear The Metrics', onClick: onClearMetricsHandler }}
              queryOptions={{ placeholderData: keepPreviousData }}>
              <TrendWidget metrics={transformedFilters.props.keyMetrics} updateFilters={onFilterChange} options={measurementOptions} />
            </Widget>
          </div>
          <div key="c" style={{ ...flex.row }}>
            <Widget
              title="Findings - Last Week"
              hideTitle
              historicalDataProps={{ hideText: true }}
              sliceProps={{ ...getSlicerBadgeProps(), hideText: true }}
              sql={widgetData.findingsLastXdays.sql(getDaysAgoDate(DAYS_AGO_PERIOD), filtersWhere)}
              transformData={widgetData.findingsLastXdays.transformData}
              height="100%"
              queryOptions={{ placeholderData: keepPreviousData }}
              sx={{ p: 0 }}>
              <Status series={statusesData.last7days.series} title="Findings - Last Week" />
            </Widget>
          </div>
          <div key="d" style={{ ...flex.row }}>
            <Widget
              sliceProps={{ ...getSlicerBadgeProps(), hideText: true }}
              height="100%"
              title="Active Findings"
              hideTitle
              historicalDataProps={{ hideText: true }}
              queryOptions={{ placeholderData: keepPreviousData }}
              transformData={widgetData.activeFindings.transformData}
              sql={widgetData.activeFindings.sqlNewTimeBuckets(getMonthAgoDate(timeComparisonXMonth), now, filtersWhere)}
              sx={{ p: 0 }}>
              <Status series={statusesData.activeFindingsSeries} title="Active Findings" />
            </Widget>
          </div>
          <div key="e" style={{ ...flex.row }}>
            <Widget
              sliceProps={{ ...getSlicerBadgeProps(), hideText: true }}
              height="100%"
              historicalDataProps={{ hideText: true }}
              queryOptions={{ placeholderData: keepPreviousData }}
              sx={{ p: 0 }}
              transformData={widgetData.uniqueCveCount.transformData}
              sql={widgetData.uniqueCveCount.sqlNewTimeBuckets(getMonthAgoDate(timeComparisonXMonth), now, filtersWhere)}>
              <Status series={statusesData.uniqueCveSeries} title="Unique CVEs" />
            </Widget>
          </div>
          <div key="f" style={{ ...flex.row }}>
            <Widget
              sliceProps={{ ...getSlicerBadgeProps(), hideText: true }}
              height="100%"
              title={widgetData.infectedAssets.title}
              hideTitle
              historicalDataProps={{ hideText: true }}
              queryOptions={{ placeholderData: keepPreviousData }}
              transformData={widgetData.infectedAssets.transformData}
              sql={widgetData.infectedAssets.sqlNewTimeBuckets(getMonthAgoDate(timeComparisonXMonth), now, filtersWhere)}
              sx={{ p: 0 }}>
              <Status series={statusesData.infectedAssetsSeries} title={widgetData.infectedAssets.title} />
            </Widget>
          </div>
          <div key="g" style={{ ...flex.row }}>
            <Widget
              sliceProps={getSlicerBadgeProps(`${pathAlias}.source_names`)}
              title="Findings by Source Names"
              transformData={widgetData.findingsByX.transformData}
              sql={widgetData.findingsByX.sql('EXPLODE(finding.source_names)', filtersWhereForSlicers, riskMetricQueryGranular(method))}
              metricKey="type"
              valKey="count"
              valLabel="Count"
              queryOptions={{ placeholderData: keepPreviousData }}>
              <Pie
                showTableSelection
                selected={slicer?.[`${pathAlias}.source_names`]?.value}
                onSelect={value => setSlicer(prev => ({ ...prev, [`${pathAlias}.source_names`]: { value } }))}
                customPalette={PIE_PALETTES.purple}
                customOthersHandler={othersHandler}
                additionalHeadCells={extraPieHeadCells}
                tooltipFields={['risk', 'count', 'share']}
                legendProps={{ hideLines: true, maxMetricWidth: isSmallScreen ? 100 : 120 }}
                type={PieSubType.Donut}
              />
            </Widget>
          </div>
          <div key="h" style={{ ...flex.row }}>
            <Widget
              sliceProps={getSlicerBadgeProps(`${pathAlias}.asset_type`)}
              title="Findings by Asset Type"
              transformData={widgetData.findingsByX.transformData}
              sql={widgetData.findingsByX.sql('asset.type', filtersWhereForSlicers, riskMetricQueryGranular(method))}
              metricKey="type"
              valKey="count"
              valLabel="Count"
              queryOptions={{ placeholderData: keepPreviousData }}>
              <Pie
                showTableSelection
                tooltipFields={['risk', 'count', 'share']}
                selected={slicer?.[`${pathAlias}.asset_type`]?.value}
                onSelect={value => setSlicer(prev => ({ ...prev, [`${pathAlias}.asset_type`]: { value } }))}
                customPalette={PIE_PALETTES.purple}
                customOthersHandler={othersHandler}
                additionalHeadCells={extraPieHeadCells}
                legendProps={{ hideLines: true, maxMetricWidth: 100 }}
                type={PieSubType.Donut}
              />
            </Widget>
          </div>
          <div key="i" style={{ ...flex.row, width: '100%', backgroundColor: theme.palette.white.main } as CSSProperties}>
            <Widget
              sliceProps={getSlicerBadgeProps()}
              height="100%"
              title={widgetData.assetsByRiskScore.title}
              queryOptions={{ placeholderData: keepPreviousData, enabled: true }}
              transformData={widgetData.newHistogram.transformData}
              sql={widgetData.newHistogram.sql(method === 'risk_max' ? 'MAX' : 'AVG', filtersWhereForHistogram)}
              sx={{ pb: 1, overflow: 'hidden' }}>
              <ActiveAssetsHistogram />
            </Widget>
          </div>
          <div
            key="j"
            style={
              {
                ...flex.col,
                padding: '24px',
                position: 'relative',
                width: '100%',
                height: '100%',
                backgroundColor: theme.palette.white.main,
              } as CSSProperties
            }>
            <Box sx={{ ...flex.itemsCenter, pb: 2, gap: 1.5, background: theme.palette.white.main }}>
              <WidgetTitle marginBottom={0}>{widgetData.riskScatter.title}</WidgetTitle>
              <Select
                isRequired
                size="small"
                style={{ width: '214px' }}
                value={scatterBy}
                onChange={(_, { value }) => {
                  setSelectedAsset(null);
                  onFilterChange('scatterBy', value);
                }}
                options={assetsOptions}
              />
            </Box>
            <RiskAndDrilldownWidget
              assetsList={scatterList}
              selectedAsset={selectedAsset}
              setSelectedAsset={setSelectedAsset}
              slicerProps={getSlicerBadgeProps()}
              scatterSql={widgetData.scatterAssetList.sql(scatterBy, filtersWhere, riskMetricQueryGranular(method))}
              drilldownSql={[
                widgetData.riskScatter.sql(
                  getMonthAgoDate(MONTH_AGO_PERIOD - 1),
                  now,
                  filtersWhereForDrilldown,
                  riskMetricQueryGranular(method)
                ),
                widgetData.riskScatterDrilldown.sql(getMonthAgoDate(MONTH_AGO_PERIOD - 1), now, filtersWhereForDrilldown),
              ]}
            />
          </div>
        </ReactGridLayout>
      </Box>
    </Box>
  );
};
export default DashboardRiskPage;
