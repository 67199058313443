import React, { ReactNode } from 'react';
import { Box, Dialog, DialogContent, DialogProps, DialogTitle, Divider, IconButton } from '@mui/material';
import { AvStepper } from './AvStepper';
import { flex } from './AvThemeProvider';
import { ReactComponent as Error } from '../assets/colorful/Fail_full.svg';
import { ReactComponent as Warn } from '../assets/colorful/Warning_full_orange.svg';
import { ReactComponent as X } from '../assets/X.svg';

const titleIconVariants = {
  error: <Error style={{ width: 30, height: 30 }} />,
  warning: <Warn style={{ width: 30, height: 30 }} />,
};

export const SIZES = {
  sm: 350,
  md: 550,
  lg: 800,
  xl: 1200,
};

interface AvDialogProps {
  title: ReactNode;
  titleVariant?: 'error' | 'warning';
  footer?: ReactNode;
  size?: DialogProps['maxWidth'];
  open: boolean;
  onClose: () => void;
  children?: ReactNode;
  noPadding?: boolean;
  fullWidth?: boolean;
  isLoading?: boolean;
}
const AvDialog: React.FC<AvDialogProps> = ({
  title,
  titleVariant,
  footer,
  size = 'md',
  open,
  onClose,
  children,
  noPadding = false,
  fullWidth,
  isLoading = false,
}) => (
  <Dialog fullWidth={fullWidth} open={open} onClose={onClose} maxWidth={size}>
    <DialogTitle sx={{ ...flex.itemsCenter, gap: 1, pl: titleVariant ? 2.75 : size === 'xl' ? 4 : 3, pr: 6 }}>
      {titleVariant ? titleIconVariants[titleVariant] : undefined}
      {title}
    </DialogTitle>
    <IconButton onClick={onClose} sx={{ position: 'absolute', top: 24, right: 24 }}>
      <X style={{ width: 20, height: 20 }} />
    </IconButton>
    <DialogContent
      sx={{
        px: noPadding ? 0 : size === 'sm' ? 3 : 4,
        width: '100%',
        minWidth: size && SIZES[size],
        maxWidth: size && SIZES[size],
        minHeight: 'auto',
      }}>
      <Box noValidate component="form">
        {children}
      </Box>
    </DialogContent>
    {footer && (
      <Box sx={{ ...flex.col, gap: 2 }}>
        {isLoading ? (
          <AvStepper isLoading={isLoading} sx={{ position: 'absolute', width: '100%', zIndex: 1, padding: 0 }} />
        ) : (
          <Divider sx={{ height: '4px' }} />
        )}
        <Box sx={{ pb: 2, px: 3 }}>{footer}</Box>
      </Box>
    )}
  </Dialog>
);

export default AvDialog;
