import React from 'react';
import { Box, useTheme } from '@mui/material';
import { flex } from '../../../components/AvThemeProvider';
import AvTooltip from '../../../components/AvTooltip';
import { PeriodBehavior } from '../../../components/DatePicker/AvDateRangePicker.constants';
import { RelativeUnit } from '../../../components/DatePicker/utils';
import { RelativeDirection } from '../../../types/filter.types';
import { iconSize } from '../../../utils/Utils';
import {
  LineLegendPosition,
  LineSubType,
  LineTypeWidget,
  PieBasicLegendPosition,
  PieLegendStyles,
  PieSubType,
  PieWithNeedleTypeWidget,
  ScoreTypeWidget,
  SortDir,
  TileSubType,
  WidgetCategory,
} from '../../CustomDashboards/types';
import { TimeBucket } from '../../Reports/types';
import { ReactComponent as ApplicationFactor } from '../../../assets/Application_Factor.svg';
import { ReactComponent as Applications } from '../../../assets/Applications.svg';
import { ReactComponent as Assets } from '../../../assets/Assets outline.svg';
import { ReactComponent as Data } from '../../../assets/Data.svg';
import { ReactComponent as Network } from '../../../assets/Network.svg';
import { ReactComponent as NotPrivate } from '../../../assets/NotPrivate.svg';
import { ReactComponent as Sorting } from '../../../assets/Sorting.svg';
import { ReactComponent as User } from '../../../assets/user_unfilled.svg';

export enum FactorType {
  GROUP_FACTOR = 'GROUP_FACTOR',
  SUB_FACTOR = 'SUB_FACTOR',
  STANDALONE_FACTOR = 'STANDALONE_FACTOR',
}

export enum FactorEntities {
  Assets = 'Assets',
  Workforce = 'Workforce',
  Applications = 'Applications',
  '3rd Parties' = '3rd Parties',
}
export const factorEntitiesToIcon = {
  [FactorEntities.Assets]: <Assets />,
  [FactorEntities.Applications]: <Applications />,
  [FactorEntities['3rd Parties']]: <ApplicationFactor />,
  [FactorEntities.Workforce]: <User />,
};
export enum FactorCategory {
  'External Attack Surface' = 'External Attack Surface',
  Compromise = 'Compromise',
  'Lateral Propagation' = 'Lateral Propagation',
  'Data Loss' = 'Data Loss',
}
export const factorCategoryToIcon = {
  [FactorCategory['External Attack Surface']]: <Network />,
  [FactorCategory.Compromise]: <NotPrivate />,
  [FactorCategory['Lateral Propagation']]: <Sorting />,
  [FactorCategory['Data Loss']]: <Data />,
};

interface FactorEntitiesTagsProps {
  values: string[];
  addName?: boolean;
}
export const FactorEntitiesList: React.FC<FactorEntitiesTagsProps> = ({ values = [], addName = false }) => {
  const theme = useTheme();
  return (
    <Box sx={{ ...flex.row, gap: 0.5 }}>
      {values.map(v => (
        <AvTooltip key={v} title={v}>
          <Box
            sx={{
              ...flex.center,
              gap: '6px',
              fontSize: 12,
              p: 0.5,
              ...(addName && { px: '6px', py: '3px' }),
              backgroundColor: theme.palette.colors.neutrals[300],
              color: theme.palette.colors.neutrals[600],
              borderRadius: '8px',
              svg: { color: theme.palette.colors.neutrals[600], ...iconSize(16) },
            }}>
            {factorEntitiesToIcon[v]}
            {addName && <Box>{v}</Box>}
          </Box>
        </AvTooltip>
      ))}
    </Box>
  );
};
export const getRiskScoreWidget = ({ customPalette }: { customPalette?: any }): PieWithNeedleTypeWidget => ({
  id: '0',
  definition: {
    title: {
      title: '',
    },
    custom: { fieldsPalette: customPalette || {}, legend: { style: PieLegendStyles.Off } },
    coords: {
      x: 0,
      y: 0,
      h: 3,
      w: 0,
    },
  },
  category: WidgetCategory.Pie,
  type: PieSubType.PieWithNeedle,
  requests: [
    {
      select: {
        dims: [
          {
            name: 'factor.category_name',
          },
        ],
        metrics: [
          {
            name: 'organization_effective_score_granular',
            alias: 'organization_effective_score_granular',
          },
        ],
      },
      groupBy: ['factor.category_name'],
      sorting: [
        {
          name: 'organization_effective_score_granular',
          dir: SortDir.DESC,
        },
      ],
      top: {
        size: 100,
        groupOthers: false,
      },
      projectionId: {
        name: 'uber_factors',
        builtIn: true,
      },
      distinct: false,
    },
  ],
});
export const externalAttackSurfacWidget: ScoreTypeWidget = {
  id: '1',
  definition: {
    title: {
      title: 'External Attack Surface',
    },
    coords: {
      x: 0,
      y: 0,
      h: 3,
      w: 0,
    },
  },
  category: WidgetCategory.Tile,
  type: TileSubType.Score,
  requests: [
    {
      filter: {
        and: {
          operands: [
            {
              expression: {
                fieldName: 'factor.category_name',
                stringCondition: {
                  equals: 'External Attack Surface',
                },
              },
            },
          ],
        },
      },
      select: {
        dims: [],
        metrics: [
          {
            name: 'category_effective_score_granular',
            alias: 'category_effective_score_granular',
          },
        ],
      },
      groupBy: [],
      sorting: [],
      top: {
        size: 100,
        groupOthers: false,
      },
      projectionId: {
        name: 'uber_factors',
        builtIn: true,
      },
      distinct: false,
    },
  ],
};
export const lateralPropagationWidget: ScoreTypeWidget = {
  id: '2',
  definition: {
    title: {
      title: 'Lateral Propagation',
    },
    coords: {
      x: 0,
      y: 0,
      h: 3,
      w: 0,
    },
  },
  category: WidgetCategory.Tile,
  type: TileSubType.Score,
  requests: [
    {
      filter: {
        and: {
          operands: [
            {
              expression: {
                fieldName: 'factor.category_name',
                stringCondition: {
                  equals: 'Lateral Propagation',
                },
              },
            },
          ],
        },
      },
      select: {
        dims: [],
        metrics: [
          {
            name: 'category_effective_score_granular',
            alias: 'category_effective_score_granular',
          },
        ],
      },
      groupBy: [],
      sorting: [],
      top: {
        size: 100,
        groupOthers: false,
      },
      projectionId: {
        name: 'uber_factors',
        builtIn: true,
      },
      distinct: false,
    },
  ],
};
export const compromiseWidget: ScoreTypeWidget = {
  id: '3',
  definition: {
    title: {
      title: 'Compromise',
    },
    coords: {
      x: 0,
      y: 0,
      h: 3,
      w: 0,
    },
  },
  category: WidgetCategory.Tile,
  type: TileSubType.Score,
  requests: [
    {
      filter: {
        and: {
          operands: [
            {
              expression: {
                fieldName: 'factor.category_name',
                stringCondition: {
                  equals: 'Compromise',
                },
              },
            },
          ],
        },
      },
      select: {
        dims: [],
        metrics: [
          {
            name: 'category_effective_score_granular',
            alias: 'category_effective_score_granular',
          },
        ],
      },
      groupBy: [],
      sorting: [],
      top: {
        size: 100,
        groupOthers: false,
      },
      projectionId: {
        name: 'uber_factors',
        builtIn: true,
      },
      distinct: false,
    },
  ],
};
export const dataLossWidget: ScoreTypeWidget = {
  id: '4',
  definition: {
    title: {
      title: 'Data Loss',
    },
    coords: {
      x: 0,
      y: 0,
      h: 3,
      w: 0,
    },
  },
  category: WidgetCategory.Tile,
  type: TileSubType.Score,
  requests: [
    {
      filter: {
        and: {
          operands: [
            {
              expression: {
                fieldName: 'factor.category_name',
                stringCondition: {
                  equals: 'Data Loss',
                },
              },
            },
          ],
        },
      },
      select: {
        dims: [],
        metrics: [
          {
            name: 'category_effective_score_granular',
            alias: 'category_effective_score_granular',
          },
        ],
      },
      groupBy: [],
      sorting: [],
      top: {
        size: 100,
        groupOthers: false,
      },
      projectionId: {
        name: 'uber_factors',
        builtIn: true,
      },
      distinct: false,
    },
  ],
};
export const getRiskScoreOverTimeWidget: LineTypeWidget = {
  id: '',
  definition: {
    title: {
      title: 'Risk Score Over Time',
    },
    coords: {
      x: 0,
      y: 0,
      h: 3,
      w: 0,
    },
    custom: {
      fieldsPalette: {},
      legend: { position: LineLegendPosition.Top },
    },
  },
  category: WidgetCategory.Line,
  type: LineSubType.Line,
  requests: [
    {
      select: {
        dims: [
          {
            name: TimeBucket.BUCKET_MONTH,
          },
        ],
        metrics: [
          {
            name: 'organization_effective_score_granular',
            alias: 'organization_effective_score_granular',
          },
          {
            name: 'organization_score_granular',
            alias: 'organization_score_granular',
          },
        ],
      },
      groupBy: [TimeBucket.BUCKET_MONTH],
      sorting: [
        {
          name: TimeBucket.BUCKET_MONTH,
          dir: SortDir.ASC,
        },
      ],
      top: {
        size: 100,
        groupOthers: false,
      },
      projectionId: {
        name: 'uber_factors',
        builtIn: true,
      },
      timeRange: {
        relative: {
          unit: RelativeUnit.MONTHS,
          value: 3,
          periodBehaviour: PeriodBehavior.last,
        },
      },
      distinct: false,
    },
  ],
};
export const getRiskByOrganizationWidget: PieWithNeedleTypeWidget = {
  id: '',
  definition: {
    title: {
      title: 'Organization Risk Score',
    },
    coords: {
      x: 0,
      y: 0,
      h: 0,
      w: 0,
    },
    custom: {
      fieldsPalette: {},
      legend: { position: PieBasicLegendPosition.Top, style: PieLegendStyles.Off, showPercentage: false },
    },
  },
  category: WidgetCategory.Pie,
  type: PieSubType.PieWithNeedle,
  requests: [
    {
      select: {
        dims: [
          {
            name: 'factor.category_name',
          },
        ],
        metrics: [
          {
            name: 'organization_effective_score_granular',
            alias: 'organization_effective_score_granular',
          },
        ],
      },
      groupBy: ['factor.category_name'],
      sorting: [
        {
          name: 'organization_effective_score_granular',
          dir: SortDir.DESC,
        },
      ],
      top: {
        size: 100,
        groupOthers: false,
      },
      projectionId: {
        name: 'uber_factors',
        builtIn: true,
      },
      distinct: false,
    },
  ],
};

export const lineQueryObject = {
  select: {
    dims: [{ name: TimeBucket.BUCKET_DAY }, { name: 'factor._key' }],
    metrics: [
      {
        name: 'factor.normalized_score',
        alias: 'score',
      },
    ],
  },
  timeRange: {
    relative: {
      unit: RelativeUnit.DAYS,
      value: 30,
      direction: RelativeDirection.PAST,
    },
  },
  groupBy: [TimeBucket.BUCKET_DAY, 'factor._key'],
  sorting: [{ name: TimeBucket.BUCKET_DAY, dir: SortDir.ASC }],
  projectionId: {
    name: 'uber_factors',
    builtIn: true,
  },
};
