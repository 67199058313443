import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { gql } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import { RefetchOptions, RefetchQueryFilters, useQuery, useQueryClient } from '@tanstack/react-query';
import { decodeJWT } from 'aws-amplify/auth';
import PropTypes from 'prop-types';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { appsOptions } from '../components/newNavigation/appbar.constants';
import { useAccessToken, useAuthentications, useLogout } from '../hooks/auth';
import useHandleError from '../hooks/useHandleError';
import { useTypeNameMap } from '../hooks/UseTypeNameMap';
import { APP_PATHS, FeatureFlags, PAGE_PATHS, Permission, ROLES } from '../types';
import { useLogoutWhenUserIsIdle } from '../utils/auth.utils';
import { entityViewConfig } from '../utils/entityViewConfig';
import {
  getAccountFromLS,
  getAccountIdFromPathName,
  getAppIdFromPathName,
  getBranchPrefix,
  getPathNameByAccount,
  setAccountFromLS,
} from '../utils/router.utils';
import { initializePendo, shouldRecordSession } from '../utils/rum.utils';
import useApi from '../utils/useApi';
import { capitalizeSentence } from '../utils/Utils';
import { GENERAL_ENTITY } from '../views/Sources/Mapping/mapping.types';
import { usePermissions } from './AvContext.hooks';
import { AccountType, AvPermissionsType } from './AvContext.types';
import { useNetworkStatus } from './AvQueryProvider';
import { AccountEntities, DescriptorType, FieldListObject } from './context.type';
import { FieldNameDescriptor, useForceRender } from './utils';

export const AvContext = React.createContext<AvContextType>({
  UIConfig: undefined,
  accessToken: '',
  accountData: { id: '', name: '', apps: [], logo: null, accountType: AccountType.POC },
  accountId: '',
  selectedApp: '',
  setSelectedApp(): void {},
  api: undefined,
  demoMode: false,
  featureFlags: undefined,
  accountEntities: {} as AvContextType['accountEntities'],
  getPathName: () => '',
  isAuthenticated: undefined,
  isLoading: false,
  measurements: { all: [], visible: [] },
  loginWithRedirect: undefined,
  logout(): void {},
  refetchUIConfig(): void {},
  setAccountId(): void {},
  typeNameMap: undefined,
  user: {
    accountId: '',
    accounts: [{ accountId: '', accountName: '', roleId: ROLES.VIEWER, userRoleId: '' }],
    permissions: [],
    role: ROLES.VIEWER,
    userId: '',
    favoriteApps: [],
    email: '',
    userName: '',
  },
  refetchAdditionalData: undefined,
  isBackOfficeAvailable: false,
  userPermissions: {
    hasAllowedPermission: () => false,
    hasAllowedPermissionToResource: () => false,
    hasAllowedEditResourcePermission: () => false,
    allowedPermissionsToResource: () => ({}),
    allowedPermissionsToPath: () => ({}),
    hasOnlyPermission: () => false,
    allowedPermissions: {},
    isInternalRole: false,
    isAvalorAdmin: false,
  },
});

const useUserAdditionalInfo = ({ authUser, api, enableRequests }) =>
  useQuery({
    queryKey: ['userAdditionalInfo', JSON.stringify(authUser)],
    queryFn: () =>
      api(GET_USER_ADDITIONAL_DATA).then(({ data }) => ({
        ...authUser,
        ...data?.getUserAdditionalData,
        role: Number(authUser.roleId),
      })),
    enabled: enableRequests && !!authUser.userId,
  });

const useAccount = ({ api, demoMode, defaultAccount, enableRequests }) => {
  const nav = useNavigate();
  return useQuery<AccountDto>({
    queryKey: ['account'],
    queryFn: () =>
      api(GET_ACCOUNT).then(({ data, errors }) => {
        if (errors) {
          console.error(errors[0]?.message);
          if (errors?.[0].extensions?.status === 404) {
            nav('error-process-request');
            return defaultAccount;
          }
          throw new Error('Failed to fetch account data', errors[0]?.message);
        }
        const account = data?.findStartupAccountByContext;
        if (shouldRecordSession) {
          datadogRum.setUser({
            ...datadogRum.getUser(),
            accountName: account?.name,
            accountType: account?.accountType,
            accountCreated: account?.createdAt,
          });
          initializePendo(datadogRum.getUser() as any);
        }
        return account;
      }),
    enabled: demoMode || enableRequests,
    retry: 10,
    retryDelay: 1000,
  });
};
const useFeatureFlags = ({ api, enableRequests, onSuccess = d => d }) =>
  useQuery({
    queryKey: ['featureFlags'],
    queryFn: () => api('feature-flags', { isJson: false, onError: () => false, onSuccess }),
    enabled: enableRequests,
    retry: 10,
    retryDelay: 1000,
  });

const flatNestedList = fields =>
  fields.flatMap(field =>
    field.type.fields && !field.repeated
      ? flatNestedList(
          field.type.fields.map(f => ({
            ...f,
            displayName: `${field.displayName || ''} ${f.displayName}`.trim(),
            name: `${field.name}.${f.name}`,
          }))
        )
      : [field]
  );

// TODO: remove this when BE will return these fields
const systemFieldsToHideOnBulkUpdate = [
  'current_status.timestamp',
  'integration_info.type',
  'integration_info.key',
  'integration_info.notification_target_id',
  'exception_integration_info.key',
  'exception_integration_info.type',
  'exception_integration_info.display_key',
  'exception_integration_info.notification_target_id',
];

const removeSPFFromSelfPath = (projId, sourceProj, usages) => (projId.name === sourceProj.name ? usages.filter(u => u !== 'SPF') : usages);
const addEVALToPathExcludingAggregatedIngress = (aggsMap, sourceProj, projId) =>
  aggsMap[sourceProj.name].name === projId.name && !aggsMap[projId.name] ? [] : ['EVAL'];

const getUsagesWithEval = ({ projId, usages }, sourceProj, aggsMap) =>
  usages.includes('SPF')
    ? [...removeSPFFromSelfPath(projId, sourceProj, usages), ...addEVALToPathExcludingAggregatedIngress(aggsMap, sourceProj, projId)]
    : usages;

const useEntities = ({ api, featureFlags, enableRequests }) => {
  const {
    data = { aggProjs: {}, ingressProjs: {}, oldIngressProjs: {}, fieldTypeMap: {}, fieldMap: {} },
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ['entities'],
    queryFn: () =>
      api(GET_ENTITIES).then(({ data }) => {
        const entityTypes = data.allEntityTypes.reduce(
          (obj, { id: { name, builtIn }, fields }) => ({
            ...obj,
            [name]: {
              fields: [
                ...data.metadataFields.map(item => ({ ...item, isMetadata: true })),
                ...flatNestedList(fields).map(field =>
                  systemFieldsToHideOnBulkUpdate.includes(field.name)
                    ? { ...field, additionalInfo: { ...field.additionalInfo, isSystemField: true } }
                    : field
                ),
              ],
              name,
              builtIn,
            },
          }),
          {}
        );
        const orderMap = Object.keys(entityTypes).reduce((obj, name, i) => ({ ...obj, [name]: i }), {});

        const oldIngressProjs = [entityTypes[GENERAL_ENTITY], ...data.ingressProjections]
          .sort((a, b) => orderMap[a.meta?.typeId.name] - orderMap[b.meta?.typeId.name])
          .reduce((obj, projection) => {
            const { builtIn, fields, name } = entityTypes[projection.meta?.typeId.name || GENERAL_ENTITY];
            return {
              ...obj,
              [name]: {
                name,
                builtIn,
                entityTypeId: projection.meta?.typeId,
                projId: projection.meta?.id,
                projDisplayName: name,
                nameMap: fields.reduce((obj, { name, displayName }) => ({ ...obj, [name]: capitalizeSentence(displayName) }), {}),
                fields: fields.map(f => ({ ...f, displayName: capitalizeSentence(f.displayName) })),
              },
            };
          }, {});

        const getFields = projName => target =>
          entityTypes[target.entityTypeId.name].fields.map(field => {
            const fieldVisibilityConfig = data.findFieldVisibilitiesByAccountId.find(dto => {
              const fieldId = `${dto.projectionName}.${dto.fieldName}`;
              return fieldId === `${target.projId.name}.${field.name}`;
            });

            return {
              ...field,
              id: `${projName === target.projId.name ? '' : `${projName}.`}${target.alias}.${field.name}`,
              value: `${target.alias}.${field.name}`,
              ingressValue: `${target.alias}.sources.${field.name}`,
              title: `${target.displayName} ${field.displayName}`,
              group: target.displayName,
              usages: target.usages,
              type: getType(field.type, field.repeated),
              originalType: field.type,
              entityTypeId: target.entityTypeId,
              mainProjId: target.projId,
              visibilityConfig: (featureFlags[FeatureFlags.FieldVisibility] && fieldVisibilityConfig) || { config: { hidden: false } },
            };
          });

        const { ingressProjs, aggProjs } = data.projectionAliasPaths.reduce(
          (acc, proj) =>
            proj.aggregates ? { ...acc, aggProjs: [...acc.aggProjs, proj] } : { ...acc, ingressProjs: [...acc.ingressProjs, proj] },
          { ingressProjs: [], aggProjs: [] }
        );

        const aggsMap = data.projectionAliasPaths.reduce((obj, { projId, aggregates }) => ({ ...obj, [projId.name]: aggregates }), {});

        const parseProj = projs =>
          projs
            .sort((a, b) => orderMap[a.entityTypeId.name] - orderMap[b.entityTypeId.name])
            .reduce((obj, { projId, entityTypeId, projDisplayName, aliases: originalAliases, aggregates }) => {
              const aliases = originalAliases.map(item => ({ ...item, usages: getUsagesWithEval(item, projId, aggsMap) }));
              const fields = aliases.flatMap(getFields(projId.name));
              const selfTarget = aliases.find(({ projId: { name } }) => name === projId.name);
              return {
                ...obj,
                [projId.name]: {
                  projId,
                  projDisplayName,
                  entityTypeId,
                  aggregates: aggregates || { builtIn: null, name: null },
                  pathAlias: selfTarget.alias,
                  fields: getFields(projId.name)(selfTarget),
                  nameMap: fields.reduce((obj, { name, displayName }) => ({ ...obj, [name]: displayName }), {}),
                  typeMap: fields.reduce((obj, { value, type }) => ({ ...obj, [value]: type }), {}),
                  fieldList: fields.reduce(
                    (usagesObj, { usages, ...field }) => ({
                      ...usagesObj,
                      ...usages.reduce((obj, usage) => ({ ...obj, [usage]: [...(usagesObj[usage] || []), field] }), {}),
                    }),
                    {}
                  ),
                },
              };
            }, {}) as AccountEntities;

        const parsedAggProjs = parseProj(aggProjs);
        const parsedIngressProjs = parseProj(ingressProjs);

        return {
          aggProjs: parsedAggProjs,
          ingressProjs: parsedIngressProjs,
          oldIngressProjs,
          fieldTypeMap: Object.values(parsedAggProjs).reduce((map, { typeMap }) => ({ ...map, ...typeMap }), {}),
          fieldMap: Object.values(parsedAggProjs).reduce(
            (map, { fieldList }) => ({
              ...map,
              ...Object.values(fieldList).reduce(
                (bigMap, list) => ({
                  ...bigMap,
                  ...list.reduce((obj, field) => ({ ...obj, [field.value]: field }), {}),
                }),
                {}
              ),
            }),
            {}
          ),
        };
      }),
    enabled: enableRequests && Object.keys(featureFlags).length > 1,
    retry: 10,
    retryDelay: 1000,
  });
  return { data: { ...data, isLoading, refetch }, isLoading, error };
};

export const getType = (type, repeated) => {
  const result = type.name?.includes('Timestamp')
    ? 'DATE'
    : type.fields
      ? 'MESSAGE'
      : DescriptorType[type.kind] || FieldNameDescriptor[type.name] || type.name;
  return repeated ? [result] : result;
};

const useAccountId = () => {
  const location = useLocation();
  const id = getBranchPrefix() ? getAccountFromLS() : getAccountIdFromPathName(location.pathname);
  return id === 'error-process-request' ? '' : id;
};

export const useMeasurements = ({ api, enableRequests }: { api; enableRequests? }) =>
  useQuery({
    queryKey: ['measurementList'],
    queryFn: () =>
      api(GET_MEASUREMENT).then(({ data }) => {
        const all = data?.findMeasurementsByAccountId.map(v => {
          const { measurement, ...rest } = v;
          return {
            ...measurement,
            ...rest,
            type: DescriptorType.TYPE_DOUBLE,
          };
        });

        return { all, visible: all.filter(({ hidden }) => !hidden) };
      }),
    enabled: enableRequests,
  });

const useUiConfig = ({ api, enableRequests }) =>
  useQuery({
    queryKey: ['ui_config'],
    queryFn: () => api(GET_UI_CONFIG, { onSuccess: ({ data }) => data.getUIConfigurationByProjection }),
    enabled: enableRequests,
    retry: 10,
    retryDelay: 1000,
  });

export default function AvContextProvider({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const handleError = useHandleError();
  const {
    user: authUser,
    isLoading: loadingAuth,
    isAuthenticated,
    getAccessTokenSilently,
    logout: authLogout,
    loginWithRedirect,
  } = useAuthentications();
  const accountId = useAccountId();
  const client = useQueryClient();
  const { isOnline } = useNetworkStatus();
  const {
    isPending: loadingToken,
    data: accessToken,
    refetch: refetchAccessToken,
  } = useAccessToken(getAccessTokenSilently, isAuthenticated, accountId, isOnline);
  const [searchParams] = useSearchParams();
  const demoMode = !!searchParams.get('demoMode');
  const logout = useLogout(authLogout, loginWithRedirect);
  const [featureFlags, setFeatureFlags] = useState({});
  const api = useApi(demoMode, accountId, logout, refetchAccessToken, featureFlags);
  const selectedAppId = getAppIdFromPathName(location.pathname) || '';
  const [selectedApp, setSelectedApp] = useState(selectedAppId || '');
  const forceRender = useForceRender();
  const defaultAccount: AccountDto = { id: '', name: '', apps: [], logo: null, accountType: AccountType.POC };
  const enableRequests = !!accountId && !!accessToken && accountId === decodeJWT(accessToken).payload!.account_id;
  const {
    data: accountData = defaultAccount,
    refetch: refetchAccountData,
    isLoading: isLoadingAccount,
    error: accountError,
  } = useAccount({ api, demoMode, defaultAccount, enableRequests });

  const supportOldTicketRoute = () => {
    const { search } = window.location;
    const pathname = window.location.pathname?.split('/')?.slice(2)?.join('/');
    const ticketCategory = searchParams.get('ticketCategory');
    const ticketApp = ticketCategory && ticketCategory !== entityViewConfig.Ticket.app ? APP_PATHS.DETECTIONS : APP_PATHS.VULNERABILITIES;
    setSelectedApp(ticketApp);
    navigate(getPathNameByAccount(accountId, ticketApp)(pathname, search));
  };

  useEffect(() => {
    if (selectedAppId) {
      if (selectedAppId === PAGE_PATHS.TICKETS) {
        supportOldTicketRoute();
      } else {
        setSelectedApp(selectedAppId);
      }
    }
  }, [selectedAppId]);

  const previousAccount = useRef(accountId);

  useEffect(() => {
    if (previousAccount.current !== accountId) {
      switchAccountClearCache(accountId);
      forceRender();
    }
  }, [accountId]);

  const switchAccountClearCache = id => {
    previousAccount.current = id;
    client.clear();
  };

  const setAccountId = id => {
    if (getBranchPrefix()) {
      setAccountFromLS(accountId);
    }
    if (location.pathname === '/') {
      navigate(`${getBranchPrefix() ? `${getBranchPrefix()}/` : ''}${id}`);
    } else {
      switchAccountClearCache(id);
    }
  };

  const {
    data: user = { permissions: '' },
    isLoading: isLoadingUser,
    refetch: refetchAdditionalData,
  } = useUserAdditionalInfo({ authUser, api, enableRequests });
  const { isLoading: isLoadingFeatureFlags, error: ffError } = useFeatureFlags({ api, enableRequests, onSuccess: setFeatureFlags });
  useLogoutWhenUserIsIdle({ logout });
  const { data: UIConfig = {}, isLoading: isLoadingUIConfig, refetch: refetchUIConfig } = useUiConfig({ api, enableRequests });
  const { data: typeNameMap, isLoading: isLoadingSources } = useTypeNameMap({ api, enableRequests });
  const { data: measurements } = useMeasurements({ api, enableRequests });
  const { data: accountEntities, isLoading: loadingFields, error: entitiesError } = useEntities({ api, featureFlags, enableRequests });
  const userPermissions = usePermissions({ featureFlags, user });
  const isBackOfficeAvailable = userPermissions.hasAllowedPermission({
    path: PAGE_PATHS.BACKOFFICE_ACTIONS,
    permission: Permission.UPDATE,
  });
  useEffect(() => {
    if (user?.role && user.role === ROLES.PRE_POV) {
      setSelectedApp(APP_PATHS.PLATFORM);
    } else if (accountData.apps.length && !selectedApp && user?.role) {
      const userApps = appsOptions.find(
        ({ rolesPermission, id }) =>
          rolesPermission.includes(user?.role) && accountData.apps.map(({ name }) => APP_PATHS[name]).includes(id)
      );
      setSelectedApp(userApps?.id);
    }
  }, [accountData.apps, user.role]);

  const error = accountError || ffError || entitiesError;
  if (error) {
    handleError(error);
    throw new Error(
      `${
        accountError
          ? 'failed to fetch account data'
          : ffError
            ? 'failed to fetch feature flags'
            : entitiesError
              ? 'Failed to fetch entities'
              : 'contextError'
      } ${error}`
    );
  }

  const value = useMemo(
    () => ({
      accountId,
      setAccountId,
      selectedApp,
      setSelectedApp,
      demoMode,
      accessToken,
      featureFlags,
      logout,
      isLoading:
        loadingToken ||
        isLoadingUser ||
        isLoadingFeatureFlags ||
        isLoadingAccount ||
        isLoadingUIConfig ||
        isLoadingSources ||
        (!demoMode && loadingAuth) ||
        loadingFields,
      isAuthenticated: demoMode || isAuthenticated,
      user,
      refetchAdditionalData,
      accountEntities,
      typeNameMap,
      measurements,
      accountData,
      refetchAccountData,
      UIConfig,
      api,
      loginWithRedirect,
      getPathName: getPathNameByAccount(accountId, selectedApp),
      refetchUIConfig,
      isBackOfficeAvailable,
      userPermissions,
    }),
    [
      accountId,
      accessToken,
      featureFlags,
      loadingAuth,
      isLoadingUser,
      loadingFields,
      loadingToken,
      isLoadingAccount,
      accountEntities,
      typeNameMap,
      measurements,
      isLoadingFeatureFlags,
      isLoadingUIConfig,
      isAuthenticated,
      user,
      accountData,
      UIConfig,
      selectedApp,
      userPermissions,
    ]
  );

  return <AvContext.Provider value={value}>{children}</AvContext.Provider>;
}
AvContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useAvContext = () => useContext(AvContext);

const GET_ENTITIES = gql`
  query allEntityTypes {
    ingressProjections {
      meta {
        id {
          name
          builtIn
        }
        typeId {
          name
          builtIn
        }
      }
    }
    allEntityTypes {
      id {
        name
        builtIn
      }
      fields {
        name
        builtIn
        displayName
        type
        repeated
        tags
        additionalInfo {
          hideFromMapping
        }
      }
    }
    metadataFields {
      name
      builtIn
      displayName
      type
      repeated
      tags: tagsList
    }
    projectionAliasPaths(includeIngressAliases: true) {
      projId {
        name
        builtIn
      }
      entityTypeId {
        name
        builtIn
      }
      projDisplayName
      aggregates {
        name
        builtIn
      }
      aliases {
        projId {
          name
          builtIn
        }
        entityTypeId {
          name
          builtIn
        }
        alias
        displayName
        usages
      }
    }
    findFieldVisibilitiesByAccountId {
      id
      projectionName
      builtInProjection
      fieldName
      config
    }
  }
`;

const GET_ACCOUNT = gql`
  query findStartupAccountByContext {
    findStartupAccountByContext {
      name
      logo
      accountType
      createdAt
      apps {
        id
        name
      }
    }
  }
`;

const GET_USER_ADDITIONAL_DATA = gql`
  query getUserAdditionalData {
    getUserAdditionalData {
      accounts {
        accountId
        accountName
        roleId
      }
      favoriteApps
    }
  }
`;

export const GET_MEASUREMENT = gql`
  query {
    findMeasurementsByAccountId {
      measurement
      createdByUserId
      updatedByUserId
      id
    }
  }
`;

const GET_UI_CONFIG = gql`
  query ($projectionName: String) {
    getUIConfigurationByProjection(projectionName: $projectionName)
  }
`;

export type AvContextType = {
  accountId: string;
  setAccountId: (id: string) => void;
  selectedApp: string;
  setSelectedApp: React.Dispatch<React.SetStateAction<string>>;
  demoMode: boolean;
  accessToken: string;
  featureFlags: any;
  logout: (redirectBack?: boolean) => void;
  isLoading: boolean;
  isAuthenticated: boolean | undefined;
  user: UserType;
  refetchAdditionalData: any;
  accountEntities: {
    aggProjs: AccountEntities;
    ingressProjs: AccountEntities;
    oldIngressProjs: AccountEntities;
    fieldTypeMap: Record<string, any>; // deprecated
    fieldMap: Record<string, FieldListObject>;
    isLoading: boolean;
    refetch: () => void;
  };
  typeNameMap: any;
  accountData: AccountDto;
  UIConfig: any;
  api: any;
  measurements: { all: any[]; visible: any[] };
  loginWithRedirect;
  getPathName: (path?: string, rest?: string, newSelectedApp?: string) => string;
  refetchUIConfig: (options?: RefetchOptions & RefetchQueryFilters) => void;
  isBackOfficeAvailable: boolean;
  userPermissions: AvPermissionsType;
};

export type AppDto = {
  id: string;
  name: string;
};

type AccountDto = {
  id: string;
  name: string;
  logo: any;
  apps: AppDto[];
  accountType: AccountType;
};
export type UserType = {
  accountId: string;
  accounts: [{ accountId: string; accountName: string; roleId: number; userRoleId: string }];
  permissions: any[];
  role: number;
  userId: string;
  favoriteApps: string[];
  roleName?: string;
  email: any;
  abac?: string;
  userName: string;
};
