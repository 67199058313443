import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../components/Permissions';
import { useAvContext } from '../context/AvContextProvider';
import { FeatureFlags, PAGE_PATHS, Permission } from '../types';
import { createRoutes } from './utils';

const RuleSet = lazy(() => import('../views/DataUnification/RuleSet'));
const DataUnification = lazy(() => import('../views/DataUnification/DataUnification'));
const UnificationEntities = lazy(() => import('../views/DataUnification/UnificationEntities'));
const EntityMerge = lazy(() => import('../views/DataUnification/EntityMerge'));

const routesDef = {
  [PAGE_PATHS.DATA_UNIFICATION_FIELDS]: [
    { path: '', element: () => <DataUnification />, permissions: [Permission.READ] },
    {
      path: 'set/:fieldName?',
      routes: [
        { path: '', element: () => <RuleSet />, permissions: [Permission.CREATE, Permission.UPDATE] },
        { path: 'create', element: () => <RuleSet mode="create" />, permissions: [Permission.CREATE, Permission.UPDATE] },
        { path: 'edit/:id', element: () => <RuleSet mode="edit" />, permissions: [Permission.CREATE, Permission.UPDATE] },
      ],
    },
  ],
};

const DataUnificationRoute = () => {
  const {
    userPermissions: { hasAllowedPermission },
    featureFlags,
  } = useAvContext();

  const path = PAGE_PATHS.DATA_UNIFICATION;

  return (
    <Route path={path}>
      {!featureFlags[FeatureFlags.EnhancedClustering] && (
        <Route path="">
          {createRoutes(PAGE_PATHS.DATA_UNIFICATION_FIELDS, hasAllowedPermission, routesDef[PAGE_PATHS.DATA_UNIFICATION_FIELDS])}
        </Route>
      )}

      <Route path={PAGE_PATHS.DATA_UNIFICATION_FIELDS}>
        {createRoutes(PAGE_PATHS.DATA_UNIFICATION_FIELDS, hasAllowedPermission, routesDef[PAGE_PATHS.DATA_UNIFICATION_FIELDS])}
      </Route>

      <Route
        path={`${PAGE_PATHS.DATA_UNIFICATION_ENTITIES}`}
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path: PAGE_PATHS.DATA_UNIFICATION_ENTITIES })}>
            <UnificationEntities />
          </ProtectedRoute>
        }
      />

      <Route
        path={`${PAGE_PATHS.DATA_UNIFICATION_ENTITIES}/:projId`}
        element={
          <ProtectedRoute
            isAllowed={
              hasAllowedPermission({ path: PAGE_PATHS.DATA_UNIFICATION_ENTITIES }) ||
              hasAllowedPermission({ path: PAGE_PATHS.DATA_UNIFICATION_ENTITIES, permission: Permission.CREATE }) ||
              hasAllowedPermission({ path: PAGE_PATHS.DATA_UNIFICATION_ENTITIES, permission: Permission.UPDATE }) ||
              hasAllowedPermission({ path: PAGE_PATHS.DATA_UNIFICATION_ENTITIES, permission: Permission.DELETE })
            }>
            <EntityMerge />
          </ProtectedRoute>
        }
      />

      <Route
        path={`${PAGE_PATHS.DATA_UNIFICATION_ENTITIES}/:projId/:ruleId`}
        element={
          <ProtectedRoute
            isAllowed={
              hasAllowedPermission({ path: PAGE_PATHS.DATA_UNIFICATION_ENTITIES }) ||
              hasAllowedPermission({ path: PAGE_PATHS.DATA_UNIFICATION_ENTITIES, permission: Permission.CREATE }) ||
              hasAllowedPermission({ path: PAGE_PATHS.DATA_UNIFICATION_ENTITIES, permission: Permission.UPDATE }) ||
              hasAllowedPermission({ path: PAGE_PATHS.DATA_UNIFICATION_ENTITIES, permission: Permission.DELETE })
            }>
            <EntityMerge />
          </ProtectedRoute>
        }
      />
    </Route>
  );
};

export default DataUnificationRoute;
