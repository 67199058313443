import { Filter } from '../../../types/filter.types';
import { DwQueryRequest, Field } from './QueryObject.types';

export enum DashboardMode {
  View = 'VIEW',
  Edit = 'EDIT',
}

export type WidgetTitle = {
  title: string;
};

export enum WidgetCategory {
  Bar = 'BAR_CATEGORY', // stacked,line are metadata
  Pie = 'PIE_CATEGORY', // pie/donut are metadata
  Table = 'TABLE_CATEGORY',
  Line = 'LINE_CATEGORY',
  Tile = 'TILE_CATEGORY',
  Text = 'TEXT_CATEGORY',
  // ...
}

export type Coords = {
  x: number;
  y: number;
  h: number;
  w: number;
};

export type BaseWidget = {
  id?: string;
  requests: DwQueryRequest[];
  definition: WidgetDefinition;
  drillDownHierarchy?: { fields: Field[]; activeIndex: number; drillDownValues: string[]; expendedIds?: string[] };
};

export type WidgetDefinition = {
  coords: Coords;
  title: WidgetTitle;
};

export type CommonWidgetProps = {
  setWidget?: any;
  data?: Record<string, any>[];
  responseTotals?: Record<string, any>;
  isLoading?: boolean;
  selected?: any[];
  onSelect?: (v) => void;
  globalFilters?: Filter;
};
