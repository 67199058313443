export enum RelativeDateType {
  Next = 'next',
  Last = 'last',
  Previous = 'previous',
  Before = 'before',
}
export enum RelativeDatePeriod {
  Minutes = 'minutes',
  Hours = 'hours',
  Days = 'days',
  Weeks = 'weeks',
  OldMonths = 'monthsRelative',
  Months = 'months',
}

export enum RelativeDateTitle {
  LastMonth = 'Last Month',
  LastThreeMonths = 'Last 3 Months',
}

export enum PeriodBehavior {
  last = 'LAST',
  previous = 'PREVIOUS',
  next = 'NEXT',
}

export const epochDateString = '1970-01-01T00:00:00';
